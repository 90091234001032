import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useMany,
  FilterDropdown,
  Select,
  ShowButton,
  useSelect,
  Radio,
  Space,
  EditButton,
  DeleteButton,
  FilterDropdownProps,
  Input,
  getDefaultFilter,
  HttpError,
  CrudFilters,
  Row,
  Col,
  Card,
  FormProps,
  Form,
  Icons,
  Button,
  CreateButton,
  Typography,
  message,
  Upload,
  useDrawerForm,
  useModalForm,
  AntdList,
  useSimpleList,
  ModalProps, Modal,
} from "@pankod/refine";

import {ICompany, IBatch, IBatchFilterVariables, IAdminUser, IClassroom, IBook, IClassHasBook} from "../../interfaces";
import React, {useEffect, useState} from "react";
import {authProvider, TOKEN_KEY} from "../../authProvider";
import {EditOutlined, PlusOutlined, UploadOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {SERVER_API_URL} from "../../config";
import {BookItem} from "../../components/BookItem";

const { Title, Text } = Typography;

type BookListByClassroomProps = {
  modalProps: ModalProps;
  classroom: IClassroom;
  onClose: any;
}

export const BookListByClassroom: React.FC<BookListByClassroomProps> = ({
                                                                          modalProps,
                                                                          classroom,
                                                                          onClose,
                                                                        }) => {
  const { listProps, searchFormProps, filters, queryResult } = useSimpleList<
    IClassHasBook,
    HttpError,
    { name: string; categories: string[] }
    >({
    pagination: { pageSize: 12, defaultCurrent: 2 },
    resource: `class-has-books/by-class-id/${classroom&&classroom.id?classroom.id:0}`,
    onSearch: ({ name, categories }) => {
      const productFilters: CrudFilters = [];

      productFilters.push({
        field: "categoryL1Name",
        operator: "in",
        value: categories,
      });

      productFilters.push({
        field: "name",
        operator: "contains",
        value: name ? name : null,
      });

      return productFilters;
    },
  });

  useEffect(() => {
    queryResult?.refetch();
  }, [classroom]);

  return (
    <>
      <Modal {...modalProps}
             title={`Books in class - ${classroom.name}`}
             width={1000}
             footer={[
               <Button key="back" onClick={() => onClose()} type="primary">
                 Close
               </Button>
             ]}
      >

            <AntdList
              grid={{
                gutter: 8,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 4,
              }}
              style={{
                height: "100%",

                paddingRight: "4px",
              }}
              {...listProps}
              renderItem={(item) => (
                <BookItem item={item.book!}  editShow={() => console.log('editShow')}/>
              )}
            />

      </Modal>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="horizontal" {...formProps}>
      <Form.Item label="Search" name="q">
        <Input.Search
          placeholder="Name, Author, Publisher, ArticleCode, etc."
          prefix={<Icons.SearchOutlined />}
          onSearch={(value) => formProps.form?.submit()}
        />
      </Form.Item>
    </Form>
  );
};

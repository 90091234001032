import {
  Show,
  useShow,
  Typography,
  Tag,
  Tabs,
  Row,
  Col,
  HttpError, CreateButton, Card, useNavigation
} from "@pankod/refine";
import {ICompany, IOrganization} from "../../interfaces";
import {SERVER_API_URL} from "../../config";
import {OrganizationEditModal} from "../organization/OrganizationEditModal";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {OrganizationCreateModal} from "../organization/OrganizationCreateModal";
import CompanyDiagram from "./CompanyDiagram";
import axios from "axios";
import {OrganizationListByCompany} from "../organization/OrganizationListByCompany";
import {OrganizationHasBookListByCompany} from "../organization-has-books/OrganizationHasBookListByCompany";

const { Title, Text } = Typography;

export const CompanyShow = () => {
  const [diagramData, setDiagramData] = useState(undefined);

  const { queryResult, setShowId } = useShow<ICompany>({
    resource: "companies",
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const loadDiagram = async (companyId: any) => {
    if (companyId) {
      const resp = await axios.get(`${SERVER_API_URL}api/companies/diagram/${companyId}`)
      setDiagramData(resp.data);
    }
  }

  useEffect(() => {
    loadDiagram(record?.id);
  }, [record?.id])

  return (
    <>
      <Show isLoading={isLoading}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            {record?.profilePictureId?<img src={`${SERVER_API_URL}files/${record?.profilePictureId}/preview`}/>:null}
          </Col>
          <Col span={8}>
            <Title level={5}>Code</Title>
            <Text>{record?.code}</Text>
            <Title level={5}>Name</Title>
            <Text>{record?.name}</Text>
            <Title level={5}>Status</Title>
            <Text>
              <Tag>{record?.status}</Tag>
            </Text>
          </Col>
          <Col span={8}>
            <Title level={5}>Address</Title>
            <Text>{record?.address?.address}</Text>
            <Title level={5}>Province</Title>
            <Text>{record?.address?.province}</Text>
            <Title level={5}>Phone</Title>
            <Text>{record?.phone}</Text>
          </Col>
        </Row>
      </Show>
      <br/>
      <Tabs>
        <Tabs.TabPane tab="Diagram" key="diagram">
          <Card title="Company">
            {diagramData?<CompanyDiagram data={diagramData}/>:null}
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Organization" key="organizations">
          <OrganizationListByCompany company={record} onMutationSuccess={() => loadDiagram(record?.id)}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Book" key="keys">
          <OrganizationHasBookListByCompany company={record}/>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

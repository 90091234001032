import {IAuthParams} from "../interfaces";
import axios, {AxiosResponse} from "axios";
import {SERVER_API_URL} from "../config";
import {TOKEN_KEY} from "../authProvider";

// export const API_ROOT = 'http://localhost:8080/';

export const authenticate = (auth: IAuthParams) => {
    return axios.post<any>(`${SERVER_API_URL}api/authenticate`, auth);
}

export const getAccount = () => {
    if (!axios.defaults.headers.common.Authorization) {
        const bearerToken = localStorage.getItem(TOKEN_KEY);
        if (bearerToken) {
            axios.defaults.headers.common = {
                Authorization: `${bearerToken}`,
            };
        }
    }
    return axios.get<any>(`${SERVER_API_URL}api/account`);
}
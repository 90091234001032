import {useForm, Form, Input, Select, Edit, useSelect, ModalProps, FormProps, Modal, DatePicker} from "@pankod/refine";
import {IOrganization} from "../../interfaces";
import React, {useState} from "react";
import {convertDateTimeToServer, displayDefaultDateTime } from "../../utils/date-utils";
import MomentDatePicker from "../../components/MomentDatePicker";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

type ClassroomCreateProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  organizationId: string|undefined;
};

export const ClassroomCreateModal: React.FC<ClassroomCreateProps> = ({
                                                                         modalProps,
                                                                         formProps,
                                                                          organizationId,
                                                                       }) => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const statusOptions = [{ label: 'Active', value: 'ACTIVE' }, { label: 'InActive', value: 'INACTIVE' }];
  return (
    <Modal {...modalProps}>
      <Form {...formProps}
            layout="vertical"
            initialValues={{
              expiryDate: displayDefaultDateTime(),
              status: 'ACTIVE',
            }}
            onFinish={(values) => {
              return formProps.onFinish?.({
                ...values,
                organization: { id: organizationId },
              });
            }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Note" name="notes">
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(
                <ReactMarkdown>{markdown}</ReactMarkdown>,
              )
            }
          />
        </Form.Item>
        <Form.Item label="Expiry Date" name="expiryDate" rules={[{ required: true }]}>
          <MomentDatePicker />
        </Form.Item>
        <Form.Item label="Status" name="status" rules={[{ required: true }]}>
          <Select options={statusOptions}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

import {AuthProvider, useLogout, useNavigation} from "@pankod/refine";
import {IAuthParams} from "./interfaces";
import axios, { AxiosResponse } from "axios";
import {authenticate, getAccount} from "./api/auth";

export const TOKEN_KEY = "naiin-education-auth-token";

export const authProvider: AuthProvider = {
  login: async ({ username, password, remember }) => {
    const auth: IAuthParams = {
      username,
      password,
      rememberMe: remember,
    };
    // const response: AxiosResponse = await axios.post<any>('http://localhost:8080/api/authenticate', auth);
    const response: AxiosResponse = await authenticate(auth);
    const bearerToken = response?.headers?.authorization;
    if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
      localStorage.setItem(TOKEN_KEY, bearerToken);

      axios.defaults.headers.common = {
        Authorization: `${bearerToken}`,
      };
      /*
      axiosInstance.defaults.headers = {
        Authorization: `Bearer ${bearerToken}`,
      };
       */
      return Promise.resolve();
    }
    /*
    if (username === "admin" && password === "admin") {
      localStorage.setItem(TOKEN_KEY, username);
      return Promise.resolve();
    }
     */
    return Promise.reject(new Error("username: admin, password: admin"));
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve("/");
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }
    try {
      const response: AxiosResponse = await getAccount();
      return Promise.resolve(response?.data?.authorities);
    } catch (e) {
      console.log(e);
      unAuthenticate();
      return Promise.reject();
    }
  },
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }
    try {
      const response: AxiosResponse = await getAccount();
      return Promise.resolve(response?.data);
    } catch (e) {
      console.log(e);
      unAuthenticate();
      return Promise.reject();
    }
    /*
    return Promise.resolve({
      id: 1,
    });
     */
  },
};

export const unAuthenticate = () => {
  localStorage.removeItem(TOKEN_KEY);
  window.location.href = '/';
}

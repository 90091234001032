import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine";

import { ICompany } from "../../interfaces";
import React, {useState} from "react";
import ReactMde from "react-mde";
import ReactMarkdown from "react-markdown";
import "react-mde/lib/styles/css/react-mde-all.css";

export const CompanyCreate = () => {
  const { formProps, saveButtonProps } = useForm<ICompany>();
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const statusOptions = [{ label: 'Active', value: 'ACTIVE' }, { label: 'InActive', value: 'INACTIVE' }];
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical"
            initialValues={{
              status: 'ACTIVE',
            }}
      >
        <Form.Item label="Name" name="name" required={true}>
          <Input />
        </Form.Item>
        <Form.Item label="Notes" name="notes">
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(
                <ReactMarkdown>{markdown}</ReactMarkdown>,
              )
            }
          />
        </Form.Item>
        <Form.Item label="Status" name="status" required={true}>
          <Select options={statusOptions}/>
        </Form.Item>
      </Form>
    </Create>
  );
};

import React, {useEffect, useState} from "react";

import {
  useTranslate,
  useLogout,
  useTitle,
  CanAccess,
  useNavigation, IResourceItem,
} from "@pankod/refine";
import { AntdLayout, Menu, Grid, Icons, useMenu } from "@pankod/refine";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import axios from "axios";
import {IMenu} from "../../../interfaces";
import {SERVER_API_URL} from "../../../config";
import {getAccount} from "../../../api/auth";

const { UnorderedListOutlined, LogoutOutlined, RightCircleOutlined } = Icons;

export const DynamicSider: React.FC = () => {
  const [tree, setTree] = useState<any[]>([]);
  const [menus, setMenus] = useState<IMenu[]>([]);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { mutate: logout } = useLogout();
  const Title = useTitle();
  const { SubMenu } = Menu;

  const translate = useTranslate();
  const { menuItems, selectedKey } = useMenu();
  const { push } = useNavigation();
  const breakpoint = Grid.useBreakpoint();

  const isMobile = !breakpoint.lg;

  /* eslint no-param-reassign: 0*/
  const fetchMenus = async () => {
    // const asTree = true;
    // const status = 'all';
    await getAccount();
    const resp = await axios.get<[IMenu]>(`${SERVER_API_URL}api/menus`);

    const data = resp.data;
    setMenus(data);
  }

  useEffect(() => {
    fetchMenus();
  }, []);

  /*
  const menuLoop = (data:any[]) => data.map((item: any) => {
    if (item.children) {
      return (
        <SubMenu key={item.key} title={item.name}>
          {menuLoop(item.children)}
        </SubMenu>
      );
    }
    return <Menu.Item key={item.key}>{item.name}</Menu.Item>;
  });
   */

  const renderMenus = (menus: IMenu[]) => {
    return menus.map((item: IMenu) => {
      const { id, name, url } = item;
      return (
        <Menu.Item
          key={url}
          onClick={() => {
            push(`/${url}` ?? "");
          }}
          icon={<UnorderedListOutlined />}
        >
          {name}
        </Menu.Item>
      );
    });
  }

  return (
    <AntdLayout.Sider
      style={{
        background: '#555555',
      }}
    >
      {Title && <Title collapsed={collapsed} />}
      <br/><br/>
      <Menu
        selectedKeys={[selectedKey]}
//        mode="inline"
        mode="vertical"
        onClick={({ key }) => {
          if (key === "logout") {
            logout();
            return;
          }

          if (!breakpoint.lg) {
            setCollapsed(true);
          }

          push(key as string);
        }}
      >
        {renderMenus(menus)}
        {<Menu.Item key="logout" icon={<LogoutOutlined />}>
          {translate("buttons.logout", "Logout")}
        </Menu.Item>}
      </Menu>
    </AntdLayout.Sider>
  );
};

import {useGetIdentity, usePermissions} from "@pankod/refine";
import {IAdminUser} from "../interfaces";

function isRole(roleData:any, role:string) {
  const hasRole = roleData?.includes(role);
  return hasRole;
}

export const RoleUtils = {
  isUser: (roleData:any) => isRole(roleData,'ROLE_USER'),
  isAdmin: (roleData:any) => isRole(roleData,'ROLE_ADMIN'),
  isCompanyAdmin: (roleData:any) => isRole(roleData,'ROLE_COMPANY_ADMIN'),
  isOrganizationAdmin: (roleData:any) => isRole(roleData,'ROLE_ORGANIZATION_ADMIN'),
}
import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  useSelect,
  ModalProps,
  FormProps,
  Modal,
  useTable,
  HttpError, CrudFilters, Table, TagField, Transfer
} from "@pankod/refine";
import {stringify} from "query-string";
import {
  IAdminUser,
  IBatchItem,
  IBatchItemFilterVariables,
  IBook, IClassHasUserEdit,
  ICompany,
  IOrganization,
  IOrganizationCategoryEdit
} from "../../interfaces";
import React, {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import axios from "axios";
import {SERVER_API_URL} from "../../config";
import {TransferDirection} from "antd/lib/transfer";

type ClassHasUserAddModalProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  classroomId: string|undefined;
  organizationId: string|undefined;
  data: IClassHasUserEdit|undefined;
};

export const ClassHasUserAddModal: React.FC<ClassHasUserAddModalProps> = ({
                                                                            modalProps,
                                                                            formProps,
                                                                            classroomId,
                                                                            organizationId,
                                                                            data,
                                                                          }) => {
  const [availableUsers, setAvailableUsers] = useState<any>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  useEffect(() => {
    loadAvailableUsers(organizationId);
  }, [organizationId]);
  useEffect(() => {
    setTargetKeys(data?.userIds!);
  }, [data]);
  const loadAvailableUsers = async (organizationId: any) => {
    if (classroomId) {
      const resp = await axios.get<[IAdminUser]>(`${SERVER_API_URL}api/admin/available-users/${organizationId}`);
      const list = resp.data.map((o:IAdminUser) => {
        return {
          key: o.id,
          label: `${o.login} - ${o.firstName}`,
          description: o.firstName,
        };
      });
      setAvailableUsers(list);
    }
  }

  return (
    <Modal {...modalProps} title="Add new Users">
      <Form {...formProps}
            layout="vertical"
            initialValues={{
              organizationId,
            }}
            onFinish={(values) => {
              return formProps.onFinish?.({
                classroomId,
                ...values,
              });
            }}
      >
        <Form.Item label="Users" name="userIds">
          <Transfer
            showSearch
            dataSource={availableUsers}
            titles={['All', 'Selected']}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onSelectChange={(sourceSelectedKeys, targetSelectedKeys)=>
              setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])}
            onChange={(nextTargetKeys) => setTargetKeys(nextTargetKeys)}
            render={(item) => item.label!}
            listStyle={{ width: 400, height: 400 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

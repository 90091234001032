import {
  Show,
  useShow,
  Typography,
  Tag,
  Select,
  useOne,
  useMany,
  useTable,
  List,
  Table,
  TagField,
  Space,
  ShowButton,
  EditButton,
  DeleteButton,
  Button,
  useModal,
  Modal,
  useModalForm,
  Form,
  Input,
  RefreshButton,
  Row,
  Col,
  Tabs,
  HttpError, CreateButton, Card, useNavigation, Avatar
} from "@pankod/refine";
import {SERVER_API_URL} from "../../config";
import React, {useState} from "react";
import {IClassroom, ICompany, IOrganization, IOrganizationHasBook} from "../../interfaces";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import {LibraryListModalByOrganizationHasBook} from "../library/LibraryListModalByOrganizationHasBook";

type OrganizationHasBookListByOrganizationProps = {
  organization?: IOrganization;
}

const { Title, Text } = Typography;
export const OrganizationHasBookListByOrganization:React.FC<OrganizationHasBookListByOrganizationProps> = ({ organization }) => {
  const { tableProps: organizationHasBookTableProps , tableQueryResult: organizationHasBookTableQueryResult } = useTable<IOrganizationHasBook>({
    resource: organization?`organization-has-books/by-organization-id/${organization?.id}`:'organization-has-books/by-organization-id/0',
    initialSorter: [{ field: 'id', order: 'asc'}],
  });

  const [organizationHasBook, setOrganizationHasBook] = useState<IOrganizationHasBook>({});
  const { modalProps, show: showModal, close } = useModal();

  return (<>
    <List title="Available Books">
      <Table {...organizationHasBookTableProps} rowKey="id">
        <Table.Column dataIndex="id" title="id"/>
        <Table.Column dataIndex={["book","thumbnailUrl"]} title="img"
                      render={(value) => (<div>
                        {value?<Avatar src={value} shape="square" size="large" />:null}
                      </div>)}
        />
        <Table.Column dataIndex="bookCode" title="Code"/>
        <Table.Column
          dataIndex="bookName"
          title="Name"
          render={(value) => <TagField value={value}/>}
        />
        <Table.Column<IOrganizationHasBook>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <Button
                  size="small"
                  onClick={() => {
                    setOrganizationHasBook(record);
                    showModal();
                  }}
                  icon={<EyeOutlined />}
                />
                <DeleteButton
                  size="small"
                  recordItemId={record.id}
                  resourceName="organization-has-books"
                  hideText
                  onSuccess={(value) => {
                    organizationHasBookTableQueryResult?.refetch();
                  }}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
    <LibraryListModalByOrganizationHasBook modalProps={modalProps} organizationHasBook={organizationHasBook} onClose={close}/>
  </>);
}
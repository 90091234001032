import React, { FC, useState } from 'react';
import {DatePicker} from "@pankod/refine";
import moment from "moment";
import dayjs from "dayjs";

const MomentDatePicker: FC = (props:any) => {
  const onChange = (o:any) => {
    return props.onChange(o);
  }
  return (
    <DatePicker {...props}
                value={props.value ? dayjs(props.value):props.value}
                onChange={onChange}
    />
  );
};
export default MomentDatePicker;

import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useMany,
  FilterDropdown,
  Select,
  ShowButton,
  useSelect,
  Space,
  EditButton,
  DeleteButton,
  Avatar,
  useGetIdentity,
  usePermissions,
  FormProps,
  Form,
  Input,
  Icons,
  Button,
  HttpError,
  CrudFilters,
  Col, Card, Row, useDrawerForm, CreateButton,
} from "@pankod/refine";

import {IOrganization, ICompany, IAdminUser, IUserFilterVariables} from "../../interfaces";
import {SERVER_API_URL} from "../../config";
import {UserCreateDrawer} from "./UserCreateDrawer";
import {useState} from "react";

export const UserList: React.FC = () => {
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<
    IAdminUser,
    HttpError,
    IUserFilterVariables>({
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, companyId, organizationId, activated } = params;
      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      }, {
        field: "companyId",
        operator: 'eq',
        value: companyId,
      }, {
        field: "organizationId",
        operator: 'eq',
        value: organizationId,
      }, {
        field: "activated",
        operator: 'eq',
        value: activated,
      });
      return filters;
    }
  });

  const { data: currentUser } = useGetIdentity<IAdminUser>();
  const { data: roles } = usePermissions();
  const isAdmin = roles?.includes('ROLE_ADMIN');

  const {
    drawerProps: createDrawerProps,
    formProps: createFormProps,
    saveButtonProps: createSaveButtonProps,
    show: createShow,
  } = useDrawerForm<IAdminUser, HttpError, IAdminUser>({
    action: "create",
    resource: "admin/users",
    redirect: false,
    successNotification: false,
    onMutationSuccess: () => tableQueryResult?.refetch(),
  });

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={6} xs={24}>
          <Card title="Filters">
            <Filter formProps={searchFormProps} />
          </Card>
        </Col>
        <Col lg={18} xs={24}>
          <List canCreate={isAdmin}
                pageHeaderProps={{
                  extra: <CreateButton onClick={() => createShow()} />
                }}
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="id" title="id" />
              <Table.Column dataIndex="profilePictureId" title="img"
                            render={(value) => (<div>
                              {value && <Avatar src={`${SERVER_API_URL}files/${value}/icon`} shape="square" size="large" />}
                            </div>)}
              />
              <Table.Column dataIndex="email" title="Email" sorter={{ multiple: 1 }} />
              <Table.Column dataIndex={["company", "name"]} title="Company" />
              <Table.Column dataIndex={["organization", "name"]} title="Organization" />
              <Table.Column
                dataIndex="firstName"
                title="Name"
                render={(value) => <TagField value={value} />}
                sorter={{ multiple: 2 }}
              />
              <Table.Column dataIndex="activated"
                            title="Activated"
                            render={(value) => value ? <div>true</div>:<div>false</div>}
              />
              <Table.Column<IAdminUser>
                title="Actions"
                dataIndex="actions"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <ShowButton
                        size="small"
                        recordItemId={record.id}
                      >Manage</ShowButton>
                      <EditButton
                        size="small"
                        recordItemId={record.id}
                        hideText
                      />
                    </Space>
                  );
                }}
              />
            </Table>
          </List>
        </Col>
      </Row>
      <UserCreateDrawer
        drawerProps={createDrawerProps}
        formProps={createFormProps}
        saveButtonProps={createSaveButtonProps}
      />
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const activatedOptions = [{ label: 'Active', value: true }, { label: 'InActive', value: false }];
  const [companyId, setCompanyId] = useState(0);
  const { selectProps: companySelectProps } = useSelect<ICompany>({
    resource: "companies",
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
  });
  const { selectProps: organizationSelectProps, queryResult: organizationQueryResult } = useSelect<IOrganization>({
    resource: `organizations/by-company-id/${companyId}`,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    /*
    filters: [
      {
        field: "companyId",
        operator: "eq",
        value: companyId,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
     */
  });
  const updateOrganization = (id: any) => {
    setCompanyId(id?id:0);
    organizationQueryResult?.refetch();
  }
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Search" name="q">
        <Input
          placeholder="ID, Name, Email, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Company" name="companyId">
        <Select allowClear {...companySelectProps} onChange={(value) => updateOrganization(value)}/>
      </Form.Item>
      <Form.Item label="Organization" name="organizationId">
        <Select allowClear {...organizationSelectProps} />
      </Form.Item>
      <Form.Item label="Activated" name="activated">
        <Select allowClear
          options={activatedOptions}
          placeholder="Activation Status"
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
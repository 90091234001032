import {
  Typography,
  Button,
  Show,
  Space,
  useList,
  MarkdownField,
  useOne,
  Row,
  Col,
  useUpdate, Authenticated, LayoutWrapper,
} from "@pankod/refine";
import {SERVER_API_URL} from "../../config";
import AvatarUpload from "../../components/AvatarUpload";
import {authProvider, TOKEN_KEY} from "../../authProvider";
import {useEffect, useState} from "react";
const { Title, Text } = Typography;

export const UserProfile: React.FC = () => {
  const [user, setUser] = useState<any>({});
  const token = localStorage.getItem(TOKEN_KEY);
  const getUser = async () => {
    // @ts-ignore
    const resp = await authProvider?.getUserIdentity();
    setUser(resp||{});
  }
  useEffect(() => {
    getUser();
  }, []);

  const imgSrc = user?.profilePictureId?`${SERVER_API_URL}files/${user?.profilePictureId}/preview`:undefined;
  return (<>
    <Title>User Profile</Title>
    <Row>
      <Col span={8}>
        <AvatarUpload
          uploadUrl={`${SERVER_API_URL}api/account/upload-profile-picture`}
          jwtToken={token}
          onFormChange={(info:any) => console.log(info)}
          imgSrc={imgSrc}
        />
      </Col>
  </Row>
  </>);
}

export const AuthenticatedUserProfile = () => {
  return (<Authenticated>
    <LayoutWrapper>
      <UserProfile />
    </LayoutWrapper>
  </Authenticated>)
}
import {
  List,
  Table,
  useTable,
  Space,
  DeleteButton,
  Avatar,
  usePermissions,
  Button,
  HttpError,
  Col, Card, Row, useDrawerForm, CreateButton, useModalForm, useNavigation,
} from "@pankod/refine";

import {
  IClassroom,
  IClassHasBookEdit, IBook, IClassHasBook
} from "../../interfaces";
import {SERVER_API_URL} from "../../config";
import React, {useState} from "react";
import {EditOutlined, EyeOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {ClassHasBookAddModal} from "./ClassHasBookAddModal";

type ClassHasBookListProps = {
  classroom?: IClassroom;
}

export const ClassHasBookList: React.FC<ClassHasBookListProps> = ({classroom}) => {
  const { show, push } = useNavigation();
  const { data: roles } = usePermissions();
  const isAdmin = roles?.includes('ROLE_ADMIN');

  const { tableProps, searchFormProps, tableQueryResult } = useTable<
    IClassHasBook,
    HttpError>({
    resource:`class-has-books/by-class-id/${classroom?classroom.id:0}`,
    syncWithLocation: false,
  });

  // Add Modal
  const {
    modalProps: addClassHasBookModalProps,
    formProps: addClassHasBookFormProps,
    show: addClassHasBookModalShow,
    queryResult: addClassHasBookQueryResult,
  } = useModalForm<IClassHasBookEdit, HttpError>({
    action: "edit",
    resource: `class-has-books/update-dto/${classroom?classroom.id:0}`,
    redirect: false,
    onMutationSuccess: () => tableQueryResult?.refetch(),
  });

  const addClassHasBook:IClassHasBookEdit|undefined = addClassHasBookQueryResult?.data?.data;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <List canCreate={isAdmin}
                pageHeaderProps={{
                  extra: <Button onClick={() => addClassHasBookModalShow()}
                                 icon={<PlusSquareOutlined />}
                  >Add Book</Button>
                }}
                title="Books"
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex={["book", "thumbnailUrl"]} title="img"
                            render={(value) => (<div>
                              {value?<Avatar src={value} shape="square" size="large" />:null}
                            </div>)}
              />
              <Table.Column dataIndex={["book", "articleCode"]} title="Code" />
              <Table.Column dataIndex={["book", "name"]} title="Name" />
              <Table.Column dataIndex="duration" title="Duration" />
              <Table.Column dataIndex="status" title="Status" />
              <Table.Column<IClassHasBook>
                title="Actions"
                dataIndex="actions"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>

                      <DeleteButton
                        size="small"
                        resourceName={`class-has-books/${classroom?.id}`}
                        recordItemId={record?.book?.id}
                        onSuccess={(value => {
                          tableQueryResult?.refetch();
                          addClassHasBookQueryResult?.refetch();
                        })}
                        hideText
                      />
                    </Space>
                  );
                }}
              />
            </Table>
          </List>
        </Col>
      </Row>
      <ClassHasBookAddModal modalProps={addClassHasBookModalProps} formProps={addClassHasBookFormProps} classroomId={classroom?.id} organizationId={classroom?.organization?.id} data={addClassHasBook}/>
    </>
  );
};

import {
  Typography,
  useTable,
  List,
  Table,
  TagField,
  Space,
  DeleteButton,
  Button,
  Avatar
} from "@pankod/refine";
import React, {useState} from "react";
import {IAdminUser, IBook, IShelf} from "../../interfaces";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import axios from "axios";
import {SERVER_API_URL} from "../../config";

type ShelfListByBookProps = {
  book?: IBook;
}

const { Title, Text } = Typography;
export const ShelfListByBook:React.FC<ShelfListByBookProps> = ({ book }) => {
  const { tableProps: shelfTableProps , tableQueryResult: shelfTableQueryResult } = useTable<IShelf>({
    resource: `shelves/by-book-id/${book?book.id:0}`,
    initialSorter: [{ field: 'id', order: 'asc'}],
  });

  const returnBook = async (bookId:any, userId:any) => {
    const dto = { userId, bookId };
    const resp = await axios.post(`${SERVER_API_URL}api/book-borrows/return`, dto);
    // console.log(resp.data);
    shelfTableQueryResult?.refetch();
  }

  return (<>
    <List title="Shelf">
      <Table {...shelfTableProps} rowKey="id">
        <Table.Column dataIndex={["user","thumbnailUrl"]} title="img"
                      render={(value) => (<div>
                        {value?<Avatar src={value} shape="square" size="large" />:null}
                      </div>)}
        />
        <Table.Column dataIndex={["classroom", "name"]} title="Classroom"/>
        <Table.Column dataIndex={["user", "login"]} title="Borrower"/>
        <Table.Column dataIndex="bookCode" title="Code"/>
        <Table.Column dataIndex="bookName" title="Name"/>
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value) => <TagField value={value}/>}
        />
        <Table.Column<IShelf>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                {record&&record.status === 'BORROWING'?
                  <Button
                    size="small"
                    onClick={() => returnBook(record.bookId, record.user?.id)}
                    icon={<EditOutlined />}
                  >Return</Button> : null
                }
                <DeleteButton
                  size="small"
                  recordItemId={record.bookId}
                  resourceName={`shelves/${record.user?.id}`}
                  hideText
                  onSuccess={(value) => {
                    shelfTableQueryResult?.refetch();
                  }}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  </>);
}
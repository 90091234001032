import React, { FC, useState } from 'react';
import {DatePicker} from "@pankod/refine";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const MomentDateRangePicker: FC = (props:any) => {
  const onChange = (o:any) => {
    console.log(o);
    return props.onChange(o);
  }
  return (
    <RangePicker {...props}
                value={props.value ? [dayjs(props.value[0]), dayjs(props.value[1])]:props.value}
                onChange={onChange}
    />
  );
};
export default MomentDateRangePicker;

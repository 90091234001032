import {
  Typography,
  useTable,
  List,
  Table,
  TagField,
  Space,
  DeleteButton,
  Button,
  Avatar
} from "@pankod/refine";
import React, {useState} from "react";
import {IAdminUser, IQueue, IShelf} from "../../interfaces";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import axios from "axios";
import {SERVER_API_URL} from "../../config";

type QueueListByUserProps = {
  user?: IAdminUser;
}

const { Title, Text } = Typography;
export const QueueListByUser:React.FC<QueueListByUserProps> = ({ user }) => {
  const { tableProps: queueTableProps , tableQueryResult: queueTableQueryResult } = useTable<IQueue>({
    resource: `queues/by-user-id/${user?user.id:0}`,
    initialSorter: [{ field: 'id', order: 'asc'}],
  });

  const returnBook = async (bookId:any, userId:any) => {
    const dto = { userId, bookId };
    const resp = await axios.post(`${SERVER_API_URL}api/book-borrows/return`, dto);
    queueTableQueryResult?.refetch();
  }

  return (<>
    <List title="Queue">
      <Table {...queueTableProps} rowKey="id">
        <Table.Column dataIndex="id" title="id"/>
        <Table.Column dataIndex={["book","thumbnailUrl"]} title="img"
                      render={(value) => (<div>
                        {value?<Avatar src={value} shape="square" size="large" />:null}
                      </div>)}
        />
        <Table.Column dataIndex={["book","code"]} title="Code"/>
        <Table.Column dataIndex={["book","name"]} title="Name"/>
        <Table.Column dataIndex={["classroom", "name"]} title="Classroom"/>
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value) => <TagField value={value}/>}
        />
        <Table.Column<IShelf>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <DeleteButton
                  size="small"
                  recordItemId={record.id}
                  resourceName={`queues`}
                  hideText
                  onSuccess={(value) => {
                    queueTableQueryResult?.refetch();
                  }}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  </>);
}
import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useMany,
  FilterDropdown,
  Select,
  ShowButton,
  useSelect,
  Space,
  EditButton,
  DeleteButton, FormProps, Form, Input, Icons, Button, HttpError, CrudFilters, useModalForm, Tag,
} from "@pankod/refine";

import {IOrganization, ICompany, IOrganizationFilterVariables} from "../../interfaces";
import React, {useState} from "react";
import {OrganizationEditModal} from "./OrganizationEditModal";
import {EditOutlined} from "@ant-design/icons";

export const OrganizationList: React.FC = () => {
  const { tableProps, searchFormProps, tableQueryResult } = useTable<IOrganization, HttpError, IOrganizationFilterVariables>({
    initialSorter: [{ field: 'id', order: 'asc'}],
    syncWithLocation: false,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, companyId } = params;
      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      }, {
        field: "companyId",
        operator: 'eq',
        value: companyId,
      });
      return filters;
    }
  });

  // Edit OrganizationModal
  const {
    modalProps: organizationEditModalProps,
    formProps: organizationEditFormProps,
    show: organizationEditShow,
    queryResult: organizationQueryResult,
    formLoading,
  } = useModalForm<IOrganization, HttpError, IOrganization>({
    action: "edit",
    resource: "organizations",
    redirect: false,
    onMutationSuccess: () => {
      tableQueryResult?.refetch();
    },
  });
  const organization:IOrganization|undefined = organizationQueryResult?.data?.data;

  return (<>
    <List title={<Filter formProps={searchFormProps} />}>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="code" title="Code" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex="color"
          title="Theme"
          render={(value) => <Tag color={value}>&nbsp;&nbsp;&nbsp;</Tag>}
        />
        <Table.Column dataIndex={['company', 'name']} title="Company" />
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value) => <TagField value={value} />}
        />
        <Table.Column<IOrganization>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <ShowButton
                  size="small"
                  recordItemId={record.id}
                >Manage</ShowButton>
                <Button
                  size="small"
                  onClick={() => organizationEditShow(record.id)}
                  icon={<EditOutlined />}
                />
                <DeleteButton
                  size="small"
                  recordItemId={record.id}
                  hideText
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
    <OrganizationEditModal modalProps={organizationEditModalProps} formProps={organizationEditFormProps} organization={organization}/>
  </>);
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const { selectProps: companySelectProps } = useSelect<ICompany>({
    resource: "companies",
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
  });
  return (
    <Form layout="inline" {...formProps}>
      <Form.Item label="Search" name="q">
        <Input
          placeholder="ID, Name, Email, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Company" name="companyId">
        <Select allowClear {...companySelectProps} style={{width: 400}}/>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useMany,
  FilterDropdown,
  Select,
  ShowButton,
  useSelect,
  Space,
  EditButton,
  DeleteButton,
  Avatar,
  useGetIdentity,
  usePermissions,
  FormProps,
  Form,
  Input,
  Icons,
  Button,
  HttpError,
  CrudFilters,
  Col, Card, Row, useDrawerForm, CreateButton, useModalForm, useNavigation, message, Upload,
} from "@pankod/refine";

import {IOrganization, ICompany, IAdminUser, IUserFilterVariables} from "../../interfaces";
import {SERVER_API_URL} from "../../config";
import {UserCreateDrawer} from "./UserCreateDrawer";
import React, {useState} from "react";
import {UserCreateByOrganizationDrawer} from "./UserCreateByOrganizationDrawer";
import {EditOutlined, EyeOutlined, UploadOutlined} from "@ant-design/icons";
import {TOKEN_KEY} from "../../authProvider";

type UserListByOrganizationProps = {
  organization?: IOrganization;
}

function beforeUpload(file:any) {
  const isXls = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  if (!isXls) {
    message.error('You can only upload Excel file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isXls && isLt2M;
}

export const UserListByOrganization: React.FC<UserListByOrganizationProps> = ({organization}) => {
  const token = localStorage.getItem(TOKEN_KEY);
  const { show, push } = useNavigation();
  const { data: currentUser } = useGetIdentity<IAdminUser>();
  const { data: roles } = usePermissions();
  const isAdmin = roles?.includes('ROLE_ADMIN');

  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<
    IAdminUser,
    HttpError,
    IUserFilterVariables>({
    resource:`admin/users/by-organization-id/${organization?organization.id:0}`,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, activated } = params;
      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      }, {
        field: "activated",
        operator: 'eq',
        value: activated,
      });
      return filters;
    },
    syncWithLocation: false,
  });

  const {
    drawerProps: userCreateDrawerProps,
    formProps: userCreateFormProps,
    saveButtonProps: userCreateSaveButtonProps,
    show: userCreateShow,
  } = useDrawerForm<IAdminUser, HttpError, IAdminUser>({
    action: "create",
    resource: `admin/organization-users/${organization?organization.id:0}`,
    redirect: false,
    onMutationSuccess: () => tableQueryResult?.refetch(),

  });

  const uploadButtonProps = {
    name: 'file',
    action: `${SERVER_API_URL}api/user-import/upload-xls/${organization?organization.code:0}`,
    beforeUpload: beforeUpload,
    headers: {
      authorization: `${token}`,
    },
    onChange(info:any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        tableQueryResult?.refetch();
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={6} xs={24}>
          <Card title="Filters">
            <Filter formProps={searchFormProps} />
          </Card>
        </Col>
        <Col lg={18} xs={24}>
          <List canCreate={isAdmin}
                pageHeaderProps={{
                  extra: <Space>
                    <CreateButton onClick={() => userCreateShow()} />
                    <Upload {...uploadButtonProps}>
                      <Button icon={<UploadOutlined />}>Import XLS</Button>
                    </Upload>,
                  </Space>
                }}
                title="Users"
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="profilePictureId" title="img"
                            render={(value) => (<div>
                              {value?<Avatar src={`${SERVER_API_URL}files/${value}/icon`} shape="square" size="large" />:null}
                            </div>)}
              />
              <Table.Column dataIndex="login" title="Login" sorter={{ multiple: 1 }} />
              <Table.Column dataIndex={["company", "name"]} title="Company" />
              <Table.Column
                dataIndex="firstName"
                title="Name"
                render={(value) => <TagField value={value} />}
                sorter={{ multiple: 2 }}
              />
              <Table.Column dataIndex="activated"
                            title="Activated"
                            render={(value) => value ? <div>true</div>:<div>false</div>}
              />
              <Table.Column<IAdminUser>
                title="Actions"
                dataIndex="actions"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <Button
                        size="small"
                        onClick={(): void => push(`/users/show/${record.id}`)}
                        icon={<EyeOutlined />}
                      />
                      <Button
                        size="small"
                        onClick={() => push(`/users/edit/${record.id}`)}
                        icon={<EditOutlined />}
                      />
                      <DeleteButton
                        size="small"
                        resourceName={`admin/users`}
                        recordItemId={record.login}
                        onSuccess={(value => tableQueryResult?.refetch())}
                        hideText
                      />
                    </Space>
                  );
                }}
              />
            </Table>
          </List>
        </Col>
      </Row>
      <UserCreateByOrganizationDrawer
        drawerProps={userCreateDrawerProps}
        formProps={userCreateFormProps}
        saveButtonProps={userCreateSaveButtonProps}
      />
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const activatedOptions = [{ label: 'Active', value: true }, { label: 'InActive', value: false }];

  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Search" name="q">
        <Input
          placeholder="ID, Name, Email, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Activated" name="activated">
        <Select allowClear
                options={activatedOptions}
                placeholder="Activation Status"
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
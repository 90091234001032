import {
  Typography,
  useTable,
  List,
  Table,
  TagField,
  Space,
  DeleteButton,
  Button,
  Avatar
} from "@pankod/refine";
import React, {useState} from "react";
import {IAdminUser, IQueue, IShelf} from "../../interfaces";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import axios from "axios";
import {SERVER_API_URL} from "../../config";
import {convertDateTimeFromServer} from "../../utils/date-utils";

type BorrowHistoryListByUserProps = {
  user?: IAdminUser;
}

const { Title, Text } = Typography;
export const BorrowHistoryListByUser:React.FC<BorrowHistoryListByUserProps> = ({ user }) => {
  const { tableProps: historyTableProps , tableQueryResult: historyTableQueryResult } = useTable<IQueue>({
    resource: `borrow-histories/by-user-id/${user?user.id:0}`,
    initialSorter: [{ field: 'endDate', order: 'desc'}],
  });

  return (<>
    <List title="History">
      <Table {...historyTableProps} rowKey="id">
        <Table.Column dataIndex="id" title="id"/>
        <Table.Column dataIndex={["book","thumbnailUrl"]} title="img"
                      render={(value) => (<div>
                        {value?<Avatar src={value} shape="square" size="large" />:null}
                      </div>)}
        />
        <Table.Column dataIndex={["book","articleCode"]} title="Code"/>
        <Table.Column dataIndex={["book","name"]} title="Name"/>
        <Table.Column dataIndex={["classroom", "name"]} title="Classroom"/>
        <Table.Column
          dataIndex="startDate"
          title="Borrow Date"
          render={(value) => <TagField value={convertDateTimeFromServer(value)}/>}
        />
        <Table.Column
          dataIndex="endDate"
          title="Return Date"
          render={(value) => <TagField value={convertDateTimeFromServer(value)}/>}
        />
      </Table>
    </List>
  </>);
}
import {
  Create,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  ButtonProps,
  Upload,
  Grid,
  RcFile, Select, Checkbox, useSelect
} from "@pankod/refine";

// import { appwriteClient, normalizeFile } from "utility";
// import { StoreContext } from "context/store";
import {useContext, useState} from "react";
import {ICompany, IOrganization} from "../../interfaces";

type CreateUserProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const UserCreateByOrganizationDrawer: React.FC<CreateUserProps> = ({
                                                                 drawerProps,
                                                                 formProps,
                                                                 saveButtonProps,
                                                               }) => {
  const breakpoint = Grid.useBreakpoint();
  // const [store, setStore] = useContext(StoreContext);
  const langOptions = [{ label: "English", value: "en" }, { label: "Thai", value: "th"}];
  const roleOptions = ['ROLE_USER', 'ROLE_ORGANIZATION_ADMIN'];

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
      title="Create User"
    >
      <Create saveButtonProps={saveButtonProps} title="Create User">
        <Form {...formProps}
              layout="vertical"
              initialValues={{
                langKey: 'en',
                authorities: ['ROLE_USER'],
              }}
        >
          <Form.Item label="Email" name="email"  rules={[{ required: true, type: 'email' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Firstname" name="firstName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Lastname" name="lastName">
            <Input />
          </Form.Item>
          <Form.Item label="Password" name="password">
            <Input />
          </Form.Item>
          <Form.Item label="Language" name="langKey"  rules={[{ required: true }]}>
            <Select options={langOptions} />
          </Form.Item>
          <Form.Item label="Roles" name="authorities"  rules={[{ required: true }]}>
            <Checkbox.Group options={roleOptions} />
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};

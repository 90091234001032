import axios from 'axios';
import {TOKEN_KEY} from "../authProvider";
import {SERVER_API_URL} from "./index";
import {useNavigation} from "@pankod/refine";
// import { Storage } from 'react-jhipster';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

// https://stackoverflow.com/questions/43210516/how-to-redirect-from-axios-interceptor-with-react-router-v4

const setupAxiosInterceptors = (onUnauthenticated:any) => {
  const onRequestSuccess = (config:any) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  };
  const onResponseSuccess = (response:any) => response;
  const onResponseError = (err:any) => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      console.log('onUnauthenticated');
      onUnauthenticated();
    }
    console.log(status);
    console.log(err);
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;

import {
  Show,
  useShow,
  Typography,
  Tag,
  Select,
  useOne,
  useMany,
  useTable,
  List,
  Table,
  TagField,
  Space,
  ShowButton,
  EditButton,
  DeleteButton,
  Button,
  useModal,
  Modal,
  useModalForm,
  Form,
  Input,
  RefreshButton,
  Row,
  Col,
  Tabs,
  HttpError, CreateButton, Card, useNavigation
} from "@pankod/refine";
import {SERVER_API_URL} from "../../config";
import React from "react";
import {IClassroom, ICompany, IOrganization, IOrganizationHasBook} from "../../interfaces";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import {ClassroomCreateModal} from "./ClassroomCreateModal";
import {ClassroomEditModal} from "./ClassroomEditModal";

type ClassroomListByOrganizationProps = {
  organization?: IOrganization;
}

const { Title, Text } = Typography;
export const ClassroomListByOrganization:React.FC<ClassroomListByOrganizationProps> = ({ organization }) => {
  const {show} = useNavigation();
  const { tableProps, tableQueryResult } = useTable<IClassroom>({
    resource: organization?`classrooms/by-organization-id/${organization?.id}`:'classrooms/by-organization-id/0',
    initialSorter: [{ field: 'id', order: 'asc'}],
    syncWithLocation: false,
  });

  // Create Modal
  const {
    modalProps: classroomCreateModalProps,
    formProps: classroomCreateFormProps,
    show: classroomCreateModalShow,
  } = useModalForm<IClassroom, HttpError>({
    action: "create",
    resource: 'classrooms',
    redirect: false,
    onMutationSuccess: () => tableQueryResult?.refetch(),
  });

  // Edit Modal
  const {
    modalProps: classroomEditModalProps,
    formProps: classroomEditFormProps,
    show: classroomEditShow,
    queryResult: classroomQueryResult,
  } = useModalForm<IClassroom, HttpError, IClassroom>({
    action: "edit",
    resource: "classrooms",
    redirect: false,
    onMutationSuccess: () => tableQueryResult?.refetch(),
  });
  const classroom:IClassroom|undefined = classroomQueryResult?.data?.data;
  return (<>
    <List
      title="Classrooms"
      pageHeaderProps={{
        extra: <CreateButton onClick={() => classroomCreateModalShow()} />
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="id"/>
        <Table.Column dataIndex="code" title="Code"/>
        <Table.Column
          dataIndex="name"
          title="Name"
          render={(value) => <TagField value={value}/>}
        />
        <Table.Column<IClassroom>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <Button
                  size="small"
                  onClick={() => show("classrooms", record.id!)}
                  icon={<EyeOutlined />}
                >Manage</Button>
                <Button
                  size="small"
                  onClick={() => classroomEditShow(record.id)}
                  icon={<EditOutlined />}
                />
                <DeleteButton
                  size="small"
                  recordItemId={record.id}
                  resourceName="classrooms"
                  hideText
                  onSuccess={(value) => {
                    tableQueryResult?.refetch();
                  }}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
    <ClassroomCreateModal modalProps={classroomCreateModalProps} formProps={classroomCreateFormProps} organizationId={organization?.id} />
    <ClassroomEditModal modalProps={classroomEditModalProps} formProps={classroomEditFormProps} classroom={classroom}/>
  </>);
}
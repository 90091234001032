import {
  Typography,
  useTable,
  List,
  Table,
  TagField,
  Space,
  DeleteButton,
  Button,
  useModalForm,
  HttpError, CreateButton, Card, useNavigation, Tag
} from "@pankod/refine";
import React from "react";
import {
  ICompany,
  IOrganization,
} from "../../interfaces";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import {OrganizationCreateModal} from "./OrganizationCreateModal";
import {OrganizationEditModal} from "./OrganizationEditModal";

type OrganizationListByCompanyProps = {
  company?: ICompany;
  onMutationSuccess?: any;
}

const { Title, Text } = Typography;
export const OrganizationListByCompany:React.FC<OrganizationListByCompanyProps> = ({ company,
                                                                                     onMutationSuccess,
                                                                                   }) => {
  const {show} = useNavigation();
  const { tableProps, tableQueryResult } = useTable<IOrganization>({
    resource: `organizations/by-company-id/${company?company.id:0}`,
    initialSorter: [{ field: 'id', order: 'asc'}],
  });

  // Create Modal
  const {
    modalProps: organizationCreateModalProps,
    formProps: organizationCreateFormProps,
    show: organizationCreateModalShow,
  } = useModalForm<IOrganization, HttpError>({
    action: "create",
    resource: 'organizations',
    redirect: false,
    onMutationSuccess: () => {
      tableQueryResult?.refetch();
      onMutationSuccess();
    },
  });

  // Edit OrganizationModal
  const {
    modalProps: organizationEditModalProps,
    formProps: organizationEditFormProps,
    show: organizationEditShow,
    queryResult: organizationQueryResult,
    formLoading,
  } = useModalForm<IOrganization, HttpError, IOrganization>({
    action: "edit",
    resource: "organizations",
    redirect: false,
    onMutationSuccess: () => {
      tableQueryResult?.refetch();
      onMutationSuccess();
    },
  });
  const organization:IOrganization|undefined = organizationQueryResult?.data?.data;

  return (<>
    <List
      title="Organizations"
      pageHeaderProps={{
        extra: <CreateButton onClick={() => organizationCreateModalShow()} />
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="code" title="Code"/>
        <Table.Column
          dataIndex="name"
          title="Name"
          render={(value) => <TagField value={value}/>}
        />
        <Table.Column
          dataIndex="color"
          title="Theme"
          render={(value) => <Tag color={value}>&nbsp;&nbsp;&nbsp;</Tag>}
        />
        <Table.Column<IOrganization>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <Button
                  size="small"
                  onClick={() => show("organizations", record.id!)}
                  icon={<EyeOutlined />}
                >Manage</Button>
                <Button
                  size="small"
                  onClick={() => organizationEditShow(record.id)}
                  icon={<EditOutlined />}
                />
                <DeleteButton
                  size="small"
                  recordItemId={record.id}
                  resourceName="organizations"
                  hideText
                  onSuccess={(value) => {
                    tableQueryResult?.refetch();
                  }}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>

    <OrganizationCreateModal modalProps={organizationCreateModalProps} formProps={organizationCreateFormProps} companyId={company?.id} />
    <OrganizationEditModal modalProps={organizationEditModalProps} formProps={organizationEditFormProps} organization={organization}/>
  </>);
}
import React from 'react';
import ReactDOM from 'react-dom';
import { OrganizationGraph } from '@ant-design/graphs';
import {Sankey} from "@ant-design/charts";
type CompanyDiagramProps = {
  data: any
}

const BookDistributionDiagram: React.FC<CompanyDiagramProps> = ({data}) => {
  const config = {
    data,
    sourceField: 'source',
    targetField: 'target',
    weightField: 'value',
    nodeWidthRatio: 0.008,
    nodePaddingRatio: 0.03,
  };
  return <Sankey {...config} />;
}

export default BookDistributionDiagram;
import {Col, Row, Show, Tag, Typography, useShow, Tabs} from "@pankod/refine";
import {SERVER_API_URL} from "../../config";
import {IAdminUser} from "../../interfaces";
import {ClassroomListByUser} from "../classrooms/ClassroomListByUser";
import {ClassHasBookListByUser} from "../class-has-book/ClassHasBookListByUser";
import {ShelfListByUser} from "../shelf/ShelfListByUser";
import {QueueListByUser} from "../queue/QueueListByUser";
import {BorrowHistoryListByUser} from "../borrow-history/BorrowHistoryListByUser";
const { Title, Text } = Typography;

export const UserShow: React.FC = () => {
  const { queryResult, setShowId } = useShow<IAdminUser>({
    resource: 'admin/users'
  });
  const { data, isLoading } = queryResult;
  const user = data?.data;
  return (
    <>
      <Show isLoading={isLoading}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            {user?.profilePictureId?<img src={`${SERVER_API_URL}files/${user?.profilePictureId}/preview`}/>:null}
            <Title level={5}>Notes</Title>
            <Text>{user?.notes}</Text>
          </Col>
          <Col span={8}>
            <Title level={5}>Email</Title>
            <Text>{user?.email}</Text>
            <Title level={5}>Firstname</Title>
            <Text>{user?.firstName}</Text>
            <Title level={5}>Company</Title>
            <Text>{user?.company?.name}</Text>
            <Title level={5}>Organization</Title>
            <Text>{user?.organization?.name}</Text>
            <Title level={5}>Firstname</Title>
            <Text>{user?.firstName}</Text>
            <Title level={5}>Activated</Title>
            <Text>
              <Tag>{user?.activated?'Yes':'No'}</Tag>
            </Text>
          </Col>
          <Col span={8}>
            <Title level={5}>Address</Title>
            <Text>{user?.address?.address}</Text>
            <Title level={5}>Province</Title>
            <Text>{user?.address?.province}</Text>
            <Title level={5}>Postcode</Title>
            <Text>{user?.address?.postcode}</Text>
          </Col>
        </Row>
      </Show>
      <Tabs>
        <Tabs.TabPane tab="Classroom" key="classrooms">
          <ClassroomListByUser user={user}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Available Books" key="books">
          <ClassHasBookListByUser user={user} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Shelf" key="shelf">
          <ShelfListByUser user={user} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Queue" key="queue">
          <QueueListByUser user={user} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="History" key="history">
          <BorrowHistoryListByUser user={user} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
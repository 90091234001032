import {
  Typography,
  useTable,
  List,
  Table,
  TagField,
  Space,
  DeleteButton,
  Button,
  useModalForm,
  HttpError, CreateButton, Card, useNavigation, useModal
} from "@pankod/refine";
import {SERVER_API_URL} from "../../config";
import React, {useState} from "react";
import {IAdminUser, IClassroom, ICompany, IOrganization, IOrganizationHasBook} from "../../interfaces";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import {BookListByClassroom} from "../books/BookListByClassroom";

type ClassroomListByUserProps = {
  user?: IAdminUser;
}

const { Title, Text } = Typography;
export const ClassroomListByUser:React.FC<ClassroomListByUserProps> = ({ user }) => {
  // const {show} = useNavigation();
  const [classroom, setClassroom] = useState<IClassroom>({});
  const { modalProps, show: showModal, close } = useModal();
  const { tableProps, tableQueryResult } = useTable<IClassroom>({
    resource: `class-has-users/by-user-id/${user&&user.id?user.id:0}`,
    initialSorter: [{ field: 'id', order: 'asc'}],
    syncWithLocation: false,
  });

  return (<>
    <List title="Attended Classrooms">
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="id"/>
        <Table.Column dataIndex="code" title="Code"/>
        <Table.Column dataIndex="name" title="Name"/>
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value) => <TagField value={value}/>}
        />
        <Table.Column<IClassroom>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <Button
                  size="small"
                  onClick={() => {
                    setClassroom(record);
                    showModal();
                  }}
                  icon={<EyeOutlined />}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
    <BookListByClassroom modalProps={modalProps} classroom={classroom} onClose={close}/>
  </>);
}

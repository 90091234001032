import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useMany,
  FilterDropdown,
  Select,
  ShowButton,
  useSelect,
  Space,
  EditButton,
  DeleteButton,
  Avatar,
  useGetIdentity,
  usePermissions,
  FormProps,
  Form,
  Input,
  Icons,
  Button,
  HttpError,
  CrudFilters,
  Col, Card, Row, useDrawerForm, CreateButton, Typography,
} from "@pankod/refine";
import React, {useState} from "react";
import {IAdminUser, IBook, IClassHasBook, IClassroom, ICompany, IOrganization, IShelf} from "../../interfaces";
import {SERVER_API_URL} from "../../config";
import {EditOutlined} from "@ant-design/icons";
import axios from "axios";
const { Title, Text } = Typography;

type ClassHasBookListByUserProps = {
  user?: IAdminUser;
}
type IBookFilterVariables = {
  q?: string;
  userId?: string;
  classroomId?: string;
}
export const ClassHasBookListByUser: React.FC<ClassHasBookListByUserProps> = ({ user }) => {
  const [classId, setClassId] = useState(0);
  const { selectProps: classroomSelectProps } = useSelect<IClassroom>({
    resource: `class-has-users/by-user-id/${user?.id}`,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: "id",
        order: "asc"
      }
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
  });
  const { tableProps, searchFormProps, tableQueryResult } = useTable<IClassHasBook, HttpError>({
    resource: `class-has-books/by-class-id-with-shelf/${classId?classId:0}/${user?user.id:0}`
  })
  const loadBooks = (classroomId:any) => {
    setClassId(classroomId);
    tableQueryResult?.refetch();
  }
  const borrowBook = async (bookId:any, classroomId: any, userId:any, borrowInDays:number) => {
    const dto = {
      userId,
      bookId,
      classroomId,
      borrowInDays,
    };
    const resp = await axios.post<IShelf>(`${SERVER_API_URL}api/book-borrows/borrow`, dto);
    // console.log(resp.data);
    tableQueryResult?.refetch();
  }
  const returnBook = async (bookId:any, userId:any) => {
    const dto = { userId, bookId };
    const resp = await axios.post(`${SERVER_API_URL}api/book-borrows/return`, dto);
    console.log(resp.data);
    tableQueryResult?.refetch();
  }
  return <>
    <List title="Available Books">
      <Row>
        <Form.Item label="Classroom" name="classroomId">
          <Select allowClear {...classroomSelectProps}
                  style={{width: 400}}
                  onChange={((value) => {loadBooks(value)})}
          />
        </Form.Item>
      </Row>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex={["book", "thumbnailUrl"]} title="Picture"
                        render={(value) => (<div>
                          {value?<Avatar src={value} shape="square" size="large" />:null}
                        </div>)}
          />
          <Table.Column dataIndex={["book", "articleCode"]} title="ArticleCode"/>
          <Table.Column dataIndex={["book", "name"]} title="name"/>
          <Table.Column<IClassHasBook>
            title="Actions"
            dataIndex="actions"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  {record.shelf&&record.shelf.status === 'BORROWING'?
                    <Button
                      size="small"
                      onClick={() => returnBook(record.bookId, user?.id)}
                      icon={<EditOutlined />}
                    >Return</Button> :
                    <Button
                      size="small"
                      onClick={() => borrowBook(record.bookId, record.classroomId, user?.id, 30)}
                      icon={<EditOutlined />}
                    >Borrow</Button>
                  }
                </Space>
              );
            }}
          />
        </Table>
      </List>
    </List>
  </>;
}

const Filter: React.FC<{ formProps: FormProps, user: IAdminUser }> = ({ formProps, user }) => {
  const [companyId, setCompanyId] = useState(0);
  const { selectProps: classroomSelectProps } = useSelect<IClassroom>({
    resource: `class-has-users/by-user-id/${user.id}`,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
  });
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Classroom" name="classroomId">
        <Select allowClear {...classroomSelectProps}/>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Button,
  useLogin, Form, Input
} from "@pankod/refine";
import {useState} from "react";

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const Login: React.FC = () => {
  const { mutate: login, isLoading } = useLogin();
  const [form] = Form.useForm<ILoginForm>();
  const [loading, setLoading] = useState(false);

  const CardTitle = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "60px",
      }}
    >
      <p>logo</p>
    </div>
  );

  return (
    <AntdLayout.Header
      style={{
        backgroundColor: "#eff7f7",
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <h1>Naiin Library</h1>
            </div>
            <Card
              title={""}
              headStyle={{ borderBottom: 0 }}
              style={{ backgroundColor: "#ffffff48" }}
            >
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  setLoading(true);
                  login(values);
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="username"
                  label="Username"
                  rules={[{ required: true, message: 'กรุณาระบุข้อมูล' }]}
                >
                  <Input size="large" placeholder="Username" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true, message: 'กรุณาระบุข้อมูล' }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input.Password type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>

                <div style={{ width:'100%',display:'flex', alignContent:'center', justifyContent:'center'}}>
                  <Button

                    type="primary"
                    size="large"
                    htmlType="submit"
                    block
                    disabled={isLoading}
                  >
                    เข้าสู่ระบบ
                  </Button>

                </div>

              </Form>
            </Card>
            <div style={{ width:'100%',display:'flex', alignContent:'center', justifyContent:'center'}}>
              <div>Version: 1.0.0</div>
            </div>
            <div style={{ width:'100%',display:'flex', alignContent:'center', justifyContent:'center'}}>
              <div>Last updated: Feb 13, 2023 16:11</div>
            </div>
          </div>
        </Col>
      </Row>
    </AntdLayout.Header>
  );
};
import {
  Show,
  useShow,
  Typography,
  Tag,
  Select,
  useOne,
  useMany,
  useTable,
  List,
  Table,
  TagField,
  Space,
  ShowButton,
  EditButton,
  DeleteButton,
  FormProps,
  useSelect,
  Form,
  Button,
  Input,
  Icons,
  Col,
  Card,
  Row,
  CrudFilters,
  HttpError,
  useModal,
  Avatar,
} from "@pankod/refine";
import {SERVER_API_URL} from "../../config";
import React, {useState} from "react";
import {IAdminUser, IClassroom, ICompany, IOrganization, IOrganizationHasBook} from "../../interfaces";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import {LibraryListModalByOrganizationHasBook} from "../library/LibraryListModalByOrganizationHasBook";

type OrganizationHasBookListByCompanyProps = {
  company?: ICompany;
}
type IOrganizationHasBookFilterVariables = {
  q?: string;
  companyId?: string;
  organizationId?: string;
}
const { Title, Text } = Typography;
export const OrganizationHasBookListByCompany:React.FC<OrganizationHasBookListByCompanyProps> = ({ company }) => {
  const { tableProps: organizationHasBookTableProps, searchFormProps , tableQueryResult: organizationHasBookTableQueryResult } = useTable<IOrganizationHasBook, HttpError, IOrganizationHasBookFilterVariables>({
    resource: `organization-has-books/find-by-company-id/${company&&company.id?company.id:0}`,
    initialSorter: [{ field: 'id', order: 'asc'}],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, organizationId } = params;
      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      }, {
        field: "organizationId",
        operator: 'eq',
        value: organizationId,
      });
      return filters;
    }
  });

  const [organizationHasBook, setOrganizationHasBook] = useState<IOrganizationHasBook>({});
  const { modalProps, show: showModal, close } = useModal();

  return (<>
    <List title="Books"
          canCreate={false}
          pageHeaderProps={{
            subTitle: <Filter formProps={searchFormProps} company={company!}/>
          }}
    >
      <Table {...organizationHasBookTableProps} rowKey="id">
        <Table.Column dataIndex="id" title="id"/>
        <Table.Column dataIndex={["book", "thumbnailUrl"]} title="img"
                      render={(value) => (<div>
                        {value?<Avatar src={value} shape="square" size="large" />:null}
                      </div>)}
        />
        <Table.Column dataIndex="bookCode" title="Code"/>
        <Table.Column dataIndex="bookName" title="Name"/>
        <Table.Column dataIndex="concurrency" title="Total Slot"/>
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value) => <TagField value={value}/>}
        />
        <Table.Column<IOrganizationHasBook>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <Button
                  size="small"
                  onClick={() => {
                    setOrganizationHasBook(record);
                    showModal();
                  }}
                  icon={<EyeOutlined />}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
    <LibraryListModalByOrganizationHasBook modalProps={modalProps} organizationHasBook={organizationHasBook} onClose={close}/>
  </>);
}

const Filter: React.FC<{ formProps: FormProps, company: ICompany }> = ({ formProps, company }) => {
  const { selectProps: organizationSelectProps } = useSelect<IClassroom>({
    resource: `organizations/by-company-id/${company?company.id:0}`,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
  });
  return (
    <Form layout="inline" {...formProps}>
      <Form.Item label="Organization" name="organizationId">
        <Select allowClear {...organizationSelectProps} style={{width: 300}}/>
      </Form.Item>
      <Form.Item label="Search" name="q">
        <Input.Search
          placeholder="Name, Author, Publisher, ArticleCode, etc."
          prefix={<Icons.SearchOutlined />}
          onSearch={(value) => formProps.form?.submit()}
        />
      </Form.Item>
    </Form>
  );
};

import {
  useForm,
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Col,
  Row,
  Typography
} from "@pankod/refine";
import React, {useState} from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import {IOrganization} from "../../interfaces";
import AvatarUpload from "../../components/AvatarUpload";
import {SERVER_API_URL} from "../../config";
import {TOKEN_KEY} from "../../authProvider";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import ColorPicker from "../../components/ColorPicker";
const { Title, Text } = Typography;

type OrganizationEditProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  organization?: IOrganization;
};

export const OrganizationEditModal: React.FC<OrganizationEditProps> = ({
                                                                         modalProps,
                                                                         formProps,
                                                                         organization,
                                                                       }) => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const token = localStorage.getItem(TOKEN_KEY);
  const imgProfileSrc = organization?.profilePictureId?`${SERVER_API_URL}files/${organization?.profilePictureId}/preview`:undefined;
  const imgPictureSrc = organization?.pictureId?`${SERVER_API_URL}files/${organization?.pictureId}/preview`:undefined;
  return (
    <Modal {...modalProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item label="Logo">
              <AvatarUpload
                uploadUrl={`${SERVER_API_URL}api/organizations/upload-profile-picture/${organization?.id}`}
                jwtToken={token}
                onFormChange={(info:any) => console.log(info)}
                imgSrc={imgProfileSrc}
              />
            </Form.Item>
            <Form.Item label="Picture">
              <AvatarUpload
                uploadUrl={`${SERVER_API_URL}api/organizations/upload-picture/${organization?.id}`}
                jwtToken={token}
                onFormChange={(info:any) => console.log(info)}
                imgSrc={imgPictureSrc}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Title level={5}>Company</Title>
            <Text>{organization?.company?.name}</Text>
            <Form.Item label="ID" name="id" rules={[{ required: true }]}>
              <Input disabled/>
            </Form.Item>
            <Form.Item label="Code" name="code" rules={[{ required: true }]}>
              <Input disabled/>
            </Form.Item>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input/>
            </Form.Item>
            <Form.Item label="Color" name="color" rules={[{ required: true }]}>
              <ColorPicker/>
            </Form.Item>
            <Form.Item label="Notes" name="notes">
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(
                    <ReactMarkdown>{markdown}</ReactMarkdown>,
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Address" name={['address', 'address']}>
              <Input.TextArea rows={5} />
            </Form.Item>
            <Form.Item label="Amphur" name={['address', 'amphur']}>
              <Input/>
            </Form.Item>
            <Form.Item label="Province" name={['address', 'province']}>
              <Input/>
            </Form.Item>
            <Form.Item label="Postcode" name={['address', 'postcode']}>
              <Input/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  useSelect,
  ModalProps,
  FormProps,
  Modal,
  useTable,
  HttpError, CrudFilters, Table, TagField
} from "@pankod/refine";
import {stringify} from "query-string";
import {IBatchItem, IBatchItemFilterVariables, ICompany, IOrganization} from "../../interfaces";
import React, {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import axios from "axios";
import {SERVER_API_URL} from "../../config";

type OrganizationCreateProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  companyId: string|undefined;
  batchItemIds: [string];
};

export const OrganizationHasBookCreateModal: React.FC<OrganizationCreateProps> = ({
                                                                                    modalProps,
                                                                                    formProps,
                                                                                    companyId,
                                                                                    batchItemIds,
                                                                                  }) => {
  const statusOptions = [{ label: 'Active', value: 'ACTIVE' }, { label: 'InActive', value: 'INACTIVE' }];
  const [batchItems, setBatchItems] = useState<any>([]);
  useEffect(() => {
    loadBatchItems(batchItemIds);
  }, [batchItemIds]);
  const loadBatchItems = async (batchItemIds: [string]) => {
    if (batchItemIds && batchItemIds.length>0) {
      const resp = await axios.get(`${SERVER_API_URL}api/batch-items/importable-by-ids?${stringify({id: batchItemIds})}`);
      setBatchItems(resp.data);
    }
  }
  const [selectedCompanyId, setSelectedCompanyId] = useState(companyId);
  const { selectProps: companySelectProps } = useSelect<ICompany>({
    resource: "companies",
    optionLabel: 'name',
    optionValue: 'id',
    sort: [{ field: "name", order: "asc"}],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
  });
  const { selectProps: organizationSelectProps, queryResult: organizationQueryResult } = useSelect<IOrganization>({
    resource: `organizations/by-company-id/${selectedCompanyId}`,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [{ field: "name", order: "asc" }],
  });
  const updateOrganization = (id: any) => {
    setSelectedCompanyId(id?id:0);
    organizationQueryResult?.refetch();
  }
  return (
    <Modal {...modalProps}>
      <Form {...formProps}
            layout="vertical"
            onFinish={(values) => {
              const importIds = batchItems.map((o:any)=>o.id);
              return formProps.onFinish?.({
                ...values,
                // batchItemIds,
                batchItemIds: importIds,
              });
            }}
      >
        <Form.Item label="Company" name="companyId" rules={[{ required: true }]}>
          <Select allowClear {...companySelectProps} onChange={(value) => updateOrganization(value)}/>
        </Form.Item>
        <Form.Item label="Organization" name="organizationId" rules={[{ required: true }]}>
          <Select allowClear {...organizationSelectProps} />
        </Form.Item>
      </Form>
      <Table dataSource={batchItems}>
        <Table.Column dataIndex="id" title="Id"/>
        <Table.Column dataIndex="itemNo" title="No."/>
        <Table.Column dataIndex="bookName" title="Book Name" />
        <Table.Column dataIndex="concurrency" title="Available Slot" />
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value) => <TagField value={value}/>}
        />
      </Table>
    </Modal>
  );
};

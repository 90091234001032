import React, { useState, useEffect } from 'react';
import {message, Upload} from "@pankod/refine";
// import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

function getBase64(img: any, callback:any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file:any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
interface IProps {
  uploadUrl: string,
  jwtToken: string|null,
  onFormChange?: any,
  imgSrc?: string,
}

export default class AvatarUpload extends React.Component<IProps> {
  state = {
    loading: false,
    imageUrl: '',
  };

  handleChange = (info:any) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl:any) =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
      this.props.onFormChange(info);
    }
  };

  render() {
    const { loading, imageUrl } = this.state;
    const { uploadUrl, jwtToken, imgSrc } = this.props;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Upload
        name="file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={uploadUrl}
        headers={{
          authorization: `${jwtToken}`,
        }}
        beforeUpload={beforeUpload}
        onChange={(info) => this.handleChange(info)}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : imgSrc ? <img src={imgSrc} style={{ width: '100%' }} />: uploadButton }
      </Upload>
    );
  }
}


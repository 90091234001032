import {
  Form,
  ModalProps,
  FormProps,
  Modal,
  HttpError, CrudFilters, Table, TagField, Transfer, Input, InputNumber, Col
} from "@pankod/refine";
import {
  IBook, IClassHasBookEdit,
} from "../../interfaces";
import React, {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import axios from "axios";
import {SERVER_API_URL} from "../../config";
import {TransferDirection} from "antd/lib/transfer";

type ClassHasBookAddModalProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  classroomId: string|undefined;
  organizationId: string|undefined;
  data: IClassHasBookEdit|undefined;
};

export const ClassHasBookAddModal: React.FC<ClassHasBookAddModalProps> = ({
                                                                            modalProps,
                                                                            formProps,
                                                                            classroomId,
                                                                            organizationId,
                                                                            data,
                                                                          }) => {
  const [availableBooks, setAvailableBooks] = useState<any>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  useEffect(() => {
    loadAvailableBooks(organizationId);
  }, [organizationId]);
  useEffect(() => {
    setTargetKeys(data?.bookIds!);
  }, [data]);
  const loadAvailableBooks = async (organizationId: any) => {
    if (classroomId) {
      const resp = await axios.get<[IBook]>(`${SERVER_API_URL}api/organization-has-books/available-books/${organizationId}`);
      const list = resp.data.map((o:IBook) => {
        return {
          key: o.id,
          label: `${o.name}`,
          description: o.name,
        };
      });
      setAvailableBooks(list);
    }
  }

  return (
    <Modal {...modalProps} title="Add new Books">
      <Form {...formProps}
            layout="vertical"
            initialValues={{
              organizationId,
              duration: 7,
              returnAlertInDays: 3,
            }}
            onFinish={(values) => {
              return formProps.onFinish?.({
                classroomId,
                ...values,
              });
            }}
      >
        <Form.Item label="Duration" name="duration" rules={[{ required: true }]}>
          <InputNumber/>
        </Form.Item>
        <Form.Item label="returnAlertInDays" name="returnAlertInDays" rules={[{ required: true }]}>
          <InputNumber/>
        </Form.Item>
        <Form.Item label="Books" name="bookIds">
          <Transfer
            showSearch
            dataSource={availableBooks}
            titles={['All', 'Selected']}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onSelectChange={(sourceSelectedKeys, targetSelectedKeys)=>
              setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])}
            onChange={(nextTargetKeys) => setTargetKeys(nextTargetKeys)}
            render={(item) => item.label!}
            listStyle={{ width: 400, height: 400 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

import {
  Avatar,
  Card,
  Divider,
  InputNumber,
  Icons,
  Dropdown,
  Menu,
  NumberField,
  Typography,
  useTranslate,
} from "@pankod/refine";
import {IBook} from "../interfaces";

const { Text, Paragraph } = Typography;
const { CloseCircleOutlined, FormOutlined } = Icons;

type BookItemProps = {
  item: IBook;
  editShow: (id?: string | undefined) => void;
};

export const BookItem: React.FC<BookItemProps> = ({
                                                    item,
                                                    editShow,
                                                  }) => {
  const t = useTranslate();

  return (
    <Card
      style={{
        margin: "8px",
        //opacity: item.stock <= 0 ? 0.5 : 1,
      }}
      bodyStyle={{ height: "400px" }}
    >
      <div style={{ position: "absolute", top: "10px", right: "5px" }}>
        <Dropdown
          overlay={
            <Menu mode="vertical">
              <Menu.Item
                key="2"
                style={{
                  fontWeight: 500,
                }}
                icon={
                  <FormOutlined
                    style={{
                      color: "green",
                    }}
                  />
                }
                onClick={() => editShow(item.id)}
              >
                {t("stores.buttons.edit")}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Icons.MoreOutlined
            style={{
              fontSize: 24,
            }}
          />
        </Dropdown>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Avatar
            shape="square"
            size={160}
            src={item.thumbnailUrl}
            alt={item.name}
          />
        </div>
        <Divider />
        <Paragraph
          ellipsis={{ rows: 2, tooltip: true }}
          style={{
            fontSize: "18px",
            fontWeight: 800,
            marginBottom: "8px",
          }}
        >
          {item.name}
        </Paragraph>
        <Paragraph
          ellipsis={{ rows: 3, tooltip: true }}
          style={{ marginBottom: "8px" }}
        >
          {item.description}
        </Paragraph>
        <Text
          className="item-id"
          style={{
            fontSize: "18px",
            fontWeight: 700,
            color: "#999999",
          }}
        >
          #{item.articleCode}
        </Text>

      </div>
    </Card>
  );
};

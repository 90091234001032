import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useMany,
  FilterDropdown,
  Select,
  ShowButton,
  useSelect,
  Space,
  EditButton,
  DeleteButton,
  Avatar,
  useGetIdentity,
  usePermissions,
  FormProps,
  Form,
  Input,
  Icons,
  Button,
  HttpError,
  CrudFilters,
  Col, Card, Row, useDrawerForm, CreateButton, useModalForm, useNavigation,
} from "@pankod/refine";

import {
  IOrganization,
  ICompany,
  IAdminUser,
  IUserFilterVariables,
  IClassroom,
  IOrganizationCategoryEdit, IClassHasUserEdit, IClassHasUser
} from "../../interfaces";
import {SERVER_API_URL} from "../../config";
import React, {useState} from "react";
import {EditOutlined, EyeOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {ClassHasUserAddModal} from "./ClassHasUserAddModal";

type ClassHasUserListProps = {
  classroom?: IClassroom;
}

export const ClassHasUserList: React.FC<ClassHasUserListProps> = ({classroom}) => {
  const { show, push } = useNavigation();
  const { data: currentUser } = useGetIdentity<IAdminUser>();
  const { data: roles } = usePermissions();
  const isAdmin = roles?.includes('ROLE_ADMIN');

  const { tableProps, searchFormProps, tableQueryResult } = useTable<
    IAdminUser,
    HttpError>({
    resource:`class-has-users/by-class-id/${classroom?classroom.id:0}`,
    syncWithLocation: false,
  });

  // Add Modal
  const {
    modalProps: addClassHasUserModalProps,
    formProps: addClassHasUserFormProps,
    show: addClassHasUserModalShow,
    queryResult: addClassHasUserQueryResult,
  } = useModalForm<IClassHasUserEdit, HttpError>({
    action: "edit",
    resource: `class-has-users/update-dto`,
    redirect: false,
    onMutationSuccess: () => tableQueryResult?.refetch(),
  });

  const addClassHasUser:IClassHasUserEdit|undefined = addClassHasUserQueryResult?.data?.data;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <List canCreate={isAdmin}
                pageHeaderProps={{
                  extra: <Button onClick={() => addClassHasUserModalShow()}
                                 icon={<PlusSquareOutlined />}
                  >Add User</Button>
                }}
                title="Users"
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="id" title="id" />
              <Table.Column dataIndex="profilePictureId" title="img"
                            render={(value) => (<div>
                              {value?<Avatar src={`${SERVER_API_URL}files/${value}/icon`} shape="square" size="large" />:null}
                            </div>)}
              />
              <Table.Column dataIndex="login" title="Login" />
              <Table.Column dataIndex="firstName" title="Name" />
              <Table.Column<IAdminUser>
                title="Actions"
                dataIndex="actions"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <Button
                        size="small"
                        onClick={() => push(`/users/show/${record.id}`)}
                        icon={<EditOutlined />}
                      />
                      <DeleteButton
                        size="small"
                        resourceName={`class-has-users/${classroom?.id}`}
                        // recordItemId={record.userId}
                        recordItemId={record.id}
                        onSuccess={(value => {
                          tableQueryResult?.refetch();
                          // addClassHasUserQueryResult?.refetch();
                        })}
                        hideText
                      />
                    </Space>
                  );
                }}
              />
            </Table>
          </List>
        </Col>
      </Row>
      <ClassHasUserAddModal modalProps={addClassHasUserModalProps} formProps={addClassHasUserFormProps} classroomId={classroom?.id} organizationId={classroom?.organization?.id} data={addClassHasUser}/>
    </>
  );
};

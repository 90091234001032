import React, { FC, useState } from 'react';
import reactCSS from 'reactcss'
import { GithubPicker } from 'react-color';

const colors = [
  '#000000',
  '#545454',
  '#737373',
  '#999595',
  '#708090',
  '#778899',
  '#E02020',
  '#B22222',
  '#FF66CC',
  '#7852A9',
  '#9966CB',
  '#81007F',
  '#6FBBA1',
  '#66CDAA',
  '#00CED1',
  '#38B6FF',
  '#1E90FF',
  '#004AAD',
  '#008E37',
  '#4CBB17',
  '#3CB371',
  '#FCD12A',
  '#EB9605',
  '#EF820D'];

const ColorPicker : FC = (props: any) => {
  const [show, setShow] = useState(false);
  const [color, setColor] = useState(props.value);
  const onChange = (o:any) => {
    setColor(o.hex);
    return props.onChange(o.hex);
  }
  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `${color}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  // @ts-ignore
  return (
    <div>
      <div style={ styles.swatch } onClick={ () => setShow(!show) }>
        <div style={ styles.color } />
      </div>
      { show && <div style={ styles.popover as {} }>
        <div style={styles.cover as {} } onClick={ () => setShow(false) }/>
        <GithubPicker colors={colors} color={`${color}`} onChange={ onChange } />
      </div> }

    </div>
  );
}

export default ColorPicker;
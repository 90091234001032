import {
  Create,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  ButtonProps,
  Upload,
  Grid,
  RcFile, Select, Checkbox, useSelect
} from "@pankod/refine";

// import { appwriteClient, normalizeFile } from "utility";
// import { StoreContext } from "context/store";
import {useContext, useState} from "react";
import {ICompany, IOrganization} from "../../interfaces";

type CreateProductProps = {
  drawerProps: DrawerProps;
  formProps: FormProps;
  saveButtonProps: ButtonProps;
};

export const UserCreateDrawer: React.FC<CreateProductProps> = ({
                                                              drawerProps,
                                                              formProps,
                                                              saveButtonProps
                                                            }) => {
  const breakpoint = Grid.useBreakpoint();
  // const [store, setStore] = useContext(StoreContext);
  const langOptions = [{ label: "English", value: "en" }, { label: "Thai", value: "th"}];
  const roleOptions = ['ROLE_USER', 'ROLE_ADMIN'];
  const [companyId, setCompanyId] = useState(0);
  const { selectProps: companySelectProps } = useSelect<ICompany>({
    resource: "companies",
    optionLabel: 'name',
    optionValue: 'id',
    sort: [{ field: "name", order: "asc"}],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
  });
  const { selectProps: organizationSelectProps, queryResult: organizationQueryResult } = useSelect<IOrganization>({
    resource: `organizations/by-company-id/${companyId}`,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [{ field: "name", order: "asc" }],
  });
  const updateOrganization = (id: any) => {
    setCompanyId(id?id:0);
    organizationQueryResult?.refetch();
  }

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? "500px" : "100%"}
      bodyStyle={{ padding: 0 }}
    >
      <Create saveButtonProps={saveButtonProps}>
        <Form {...formProps}
              layout="vertical"
              initialValues={{
                langKey: 'en',
                authorities: ['ROLE_USER'],
              }}
        >
          <Form.Item label="Email" name="email"  rules={[{ required: true, type: 'email' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Firstname" name="firstName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Lastname" name="lastName">
            <Input />
          </Form.Item>
          <Form.Item label="Password" name="password">
            <Input />
          </Form.Item>
          <Form.Item label="Language" name="langKey"  rules={[{ required: true }]}>
            <Select options={langOptions} />
          </Form.Item>
          <Form.Item label="Company" name={["company", "id"]} rules={[{ required: true }]}>
            <Select allowClear {...companySelectProps} onChange={(value) => updateOrganization(value)}/>
          </Form.Item>
          <Form.Item label="Organization" name={["organization", "id"]} rules={[{ required: true }]}>
            <Select allowClear {...organizationSelectProps} />
          </Form.Item>
          <Form.Item label="Roles" name="authorities"  rules={[{ required: true }]}>
            <Checkbox.Group options={roleOptions} />
          </Form.Item>
        </Form>
      </Create>
    </Drawer>
  );
};

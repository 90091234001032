import {useForm, Form, Input, Select, Edit, useSelect, ModalProps, FormProps, Modal} from "@pankod/refine";
import {IOrganization} from "../../interfaces";
import React, {useState} from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

type OrganizationCreateProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  companyId: string|undefined;
};

export const OrganizationCreateModal: React.FC<OrganizationCreateProps> = ({
                                                                         modalProps,
                                                                         formProps,
                                                                         companyId,
                                                                       }) => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const statusOptions = [{ label: 'Active', value: 'ACTIVE' }, { label: 'InActive', value: 'INACTIVE' }];
  return (
    <Modal {...modalProps}>
      <Form {...formProps}
            layout="vertical"
            initialValues={{
              color: '#b80000',
              status: 'ACTIVE',
            }}
            onFinish={(values) => {
              return formProps.onFinish?.({
                ...values,
                company: { id: companyId },
              });
            }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Notes" name="notes">
          <ReactMde
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(
                <ReactMarkdown>{markdown}</ReactMarkdown>,
              )
            }
          />
        </Form.Item>
        <Form.Item label="Status" name="status" rules={[{ required: true }]}>
          <Select options={statusOptions}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

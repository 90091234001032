import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  useSelect,
  ModalProps,
  FormProps,
  Modal,
  Button,
  Row,
  Col,
  DatePicker,
  Typography, Space, Image,
} from "@pankod/refine";
import {IBatchItem, INaiinBookData, IOrganization} from "../../interfaces";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {SERVER_API_URL} from "../../config";
import {convertDateTimeToServer} from "../../utils/date-utils";
import MomentDateRangePicker from "../../components/MomentDateRangePicker";
const {Title, Text} = Typography;
const { RangePicker } = DatePicker;

type BatchItemEditProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  batchItem?: IBatchItem;
};

export const BatchItemEditModal: React.FC<BatchItemEditProps> = ({
                                                                       modalProps,
                                                                       formProps,
  batchItem,
                                                                     }) => {
  const statusOptions = [{ label: 'Active', value: 'ACTIVE' }, { label: 'InActive', value: 'INACTIVE' }];
  const [productId, setProductId] = useState('');
  const [product, setProduct] = useState<INaiinBookData>({});
  useEffect(() => {
    console.log(JSON.stringify(batchItem));
    onLoadInfo(batchItem?.productId);
  }, [batchItem])
  const onLoadInfo = async (id:any) => {
    if (id == null) return;
    const resp = await axios.get<INaiinBookData>(`${SERVER_API_URL}api/book-import/book-info-by-product-id/${id}`);
    console.log(JSON.stringify(resp.data));
    formProps?.form?.setFieldsValue({productId: resp.data.productId})
    setProduct(resp.data);
  }
  const formLayout = { labelCol: { span: 6 }, wrapperCol: { span: 18 } };
  return (
    <Modal {...modalProps}>
      <Form {...formProps} {...formLayout}
            wrapperCol={{ span: 12 }}
            layout="horizontal"
            onFinish={(values) => {
              console.log(values);
              const newActiveDateRange = [
                convertDateTimeToServer(values.activeDateRange[0]),
                convertDateTimeToServer(values.activeDateRange[1]),
              ];
              const newValues = {
                ...values,
                startDate: newActiveDateRange[0],
                endDate: newActiveDateRange[1],
                activeDateRange: newActiveDateRange,
              };
              console.log(newValues);
              return formProps.onFinish?.(newValues);
            }}
            autoComplete="off"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Id" name="id" rules={[{ required: true }]}>
              <Input readOnly={true} disabled={true}/>
            </Form.Item>
            <Form.Item label="productId" name="productId" rules={[{ required: true }]}>
              <Input readOnly={true} disabled={true}/>
            </Form.Item>
            <Form.Item label="Concurrency" name="concurrency" rules={[{ required: true }]}>
              <Input/>
            </Form.Item>
            <Form.Item label="Active" name="activeDateRange" rules={[{ required: true }]}>
              <MomentDateRangePicker/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Input.Search onSearch={(value) => onLoadInfo(value) }/>
            <Text>{product?.productName}</Text>
            <br/>
            <Text>{product?.defaultFileType}</Text>
          </Col>
          <Col span={6}>
            <Image
              width={200}
              src={product?.thumbnailFile}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

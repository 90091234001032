import {useForm, Form, Input, Select, Edit, useSelect, ModalProps, FormProps, Modal} from "@pankod/refine";
import {ICompany, IOrganization} from "../../interfaces";
import React, {useState} from "react";

type BatchCreateProps = {
  modalProps: ModalProps;
  formProps: FormProps;
};

export const BatchCreateModal: React.FC<BatchCreateProps> = ({
                                                               modalProps,
                                                               formProps,
                                                             }) => {
  const statusOptions = [{ label: 'Active', value: 'ACTIVE' }, { label: 'InActive', value: 'INACTIVE' }];
  const [companyId, setCompanyId] = useState(0);
  const { selectProps: companySelectProps } = useSelect<ICompany>({
    resource: "companies",
    optionLabel: 'name',
    optionValue: 'id',
    sort: [{ field: "name", order: "asc"}],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
  });
  const { selectProps: organizationSelectProps, queryResult: organizationQueryResult } = useSelect<IOrganization>({
    resource: `organizations/by-company-id/${companyId}`,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [{ field: "name", order: "asc" }],
  });
  const updateOrganization = (id: any) => {
    setCompanyId(id?id:0);
    organizationQueryResult?.refetch();
  }
  return (
    <Modal {...modalProps}>
      <Form {...formProps}
            wrapperCol={{ span: 12 }}
            layout="vertical"
            initialValues={{
              status: 'ACTIVE',
            }}
      >
        <Form.Item label="Name" name="filename" rules={[{ required: true }]}>
          <Input/>
        </Form.Item>
        <Form.Item label="Company" name={["company", "id"]} rules={[{ required: true }]}>
          <Select allowClear {...companySelectProps} onChange={(value) => updateOrganization(value)}/>
        </Form.Item>
        {false?
        <Form.Item label="Organization" name={["organization", "id"]}>
          <Select allowClear {...organizationSelectProps} />
        </Form.Item>:null}
        <Form.Item label="Status" name="status" rules={[{ required: true }]}>
          <Select options={statusOptions}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};
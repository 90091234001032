import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useMany,
  FilterDropdown,
  Select,
  ShowButton,
  useSelect,
  Radio,
  Space,
  EditButton,
  DeleteButton,
  FilterDropdownProps,
  Input,
  getDefaultFilter,
  HttpError,
  CrudFilters,
  Row,
  Col,
  Card,
  FormProps,
  Form,
  AntdList,
  Icons, Button, CreateButton, Typography, message, Upload, useDrawerForm, useModalForm,
} from "@pankod/refine";

import {
  ICompany,
  IBatch,
  IBatchFilterVariables,
  IAdminUser,
  IClassroom,
  IOrganization,
  IBatchItem, IBatchItemFilterVariables
} from "../../interfaces";
import React, {useEffect, useState} from "react";
import {authProvider, TOKEN_KEY} from "../../authProvider";
import {EditOutlined, PlusOutlined, UploadOutlined, PlusSquareOutlined, SendOutlined} from "@ant-design/icons";
import {SERVER_API_URL} from "../../config";
import {ClassroomCreateModal} from "../classrooms/ClassroomCreateModal";
import {OrganizationHasBookCreateModal} from "../organization-has-books/OrganizationHasBookCreateModal";
import {stringify} from "query-string";
import axios from "axios";
import {BatchItemEditModal} from "./BatchItemEditModal";

const { Title, Text } = Typography;


function beforeUpload(file:any) {
  const isXls = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  if (!isXls) {
    message.error('You can only upload Excel file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isXls && isLt2M;
}

export const BatchItemList: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<any>([],);
  const hasSelected = selectedRowKeys.length > 0;

  const token = localStorage.getItem(TOKEN_KEY);
  const [user, setUser] = useState<any>({});
  const [organizationHasBooks, setOrganizationHasBooks] = useState<any>([]);
  const getUser = async () => {
    // @ts-ignore
    const resp = await authProvider?.getUserIdentity();
    setUser(resp||{});
  }
  useEffect(() => {
    getUser();
  }, []);

  const [companyId, setCompanyId] = useState(0);
  const { selectProps: companySelectProps } = useSelect<ICompany>({
    resource: "companies",
    optionLabel: 'name',
    optionValue: 'id',
    sort: [{ field: "name", order: "asc"}],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
  });
  const { selectProps: batchItemSelectProps, queryResult: batchItemQueryResult } = useSelect<IOrganization>({
    resource: `batch-items/by-company-id/${companyId}`,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [{ field: "code", order: "asc" }],
  });
  // Create Modal
  const {
    modalProps: organizationHasBookCreateModalProps,
    formProps: organizationHasBookCreateFormProps,
    show: organizationHasBookCreateModalShow,
  } = useModalForm<IBatchItem, HttpError>({
    action: "create",
    // resource: `batch-items/by-ids?${stringify({id: selectedRowKeys})}`,
    resource: 'book-import/publish-to-organization',
    redirect: false,
    onMutationSuccess: () => tableQueryResult?.refetch(),
  });

  // Edit Modal
  const {
    modalProps: batchItemEditModalProps,
    formProps: batchItemEditFormProps,
    show: batchItemEditShow,
    queryResult: batchItemEditQueryResult,
    formLoading,
  } = useModalForm<IBatchItem, HttpError, IBatchItem>({
    action: "edit",
    resource: "batch-items",
    redirect: false,
    onMutationSuccess: () => tableQueryResult?.refetch(),
  });
  const batchItem:IBatchItem|undefined = batchItemEditQueryResult?.data?.data;

  const {tableProps, tableQueryResult, searchFormProps, filters} = useTable<
    IBatchItem,
    HttpError,
    IBatchItemFilterVariables>({
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, status, companyId, batchId } = params;
      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      }, {
        field: "companyId",
        operator: 'eq',
        value: companyId,
      }, {
        field: "batchId",
        operator: 'eq',
        value: batchId,
      }, {
        field: "status",
        operator: 'eq',
        value: status,
      });
      return filters;
    }
  });


  const uploadButtonProps = {
    name: 'file',
    action: `${SERVER_API_URL}api/book-import/upload-xls`,
    beforeUpload: beforeUpload,
    headers: {
      authorization: `${token}`,
    },
    onChange(info:any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  }


  const updateBatchItemList = (id:any) => {
    setCompanyId(id?id:0);
    batchItemQueryResult?.refetch();
  }

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const renderBatchItemDetails = (item:IBatchItem) => {
    // const resp = await axios.get(`${SERVER_API_URL}api/organization-has-books/by-batch-item-id/${item.id}`)
    // setOrganizationHasBooks(resp.data);
    if (item && item.organizationHasBookList) {
      if (item.organizationHasBookList.length > 0) {
        return <AntdList
          dataSource={item.organizationHasBookList}
          renderItem={(item) => (
            <AntdList.Item>
              {item.book?.name} {item.organization?.name}
            </AntdList.Item>
          )}
        />
      }
    }
    return null;
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: record.expired
    }),
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <Card title="Filters">
            <Filter formProps={searchFormProps} />
          </Card>
        </Col>
      </Row>
      <br/>
      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <List
            pageHeaderProps={{
              extra: <Space>
                <Text>{user?.company?.name}</Text>
                {hasSelected && (
                <Button icon={<SendOutlined />}
                        disabled={!hasSelected}
                        type="primary"
                        onClick={() => organizationHasBookCreateModalShow()}>Publish</Button>
                )}
              </Space>
            }}
          >
            <Table {...tableProps}
                   rowSelection={rowSelection}
                   expandable={{
                     expandedRowRender: (item) => renderBatchItemDetails(item),
                     rowExpandable: (item) => {
                       if (item && item.organizationHasBookList) {
                         return item?.organizationHasBookList?.length > 0;
                       }
                       return false;
                     },
                   }}
                   rowKey="id">
              <Table.Column dataIndex="id" title="id"/>
              <Table.Column dataIndex="itemNo" title="No."/>
              <Table.Column dataIndex="bookName" title="Book Name"
                            filterDropdown={(props: FilterDropdownProps) => (
                              <FilterDropdown {...props}>
                                <Input />
                              </FilterDropdown>
                            )}
                            defaultFilteredValue={getDefaultFilter("filename", filters, "contains")}
              />
              <Table.Column dataIndex={["batch","filename"]} title="Batch"/>
              <Table.Column dataIndex={["batch","company","name"]} title="Company"/>
              <Table.Column
                dataIndex="status"
                title="Status"
                render={(value, record:any) => record.expired?<TagField value="EXPIRED"/>:<TagField value={value}/>}
                filterDropdown={(props) => (
                  <FilterDropdown {...props}>
                    <Radio.Group>
                      <Radio value="ACTIVE">ACTIVE</Radio>
                      <Radio value="INACTIVE">INACTIVE</Radio>
                    </Radio.Group>
                  </FilterDropdown>
                )}
              />
              <Table.Column<IBatch>
                title="Actions"
                dataIndex="actions"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <Button
                        size="small"
                        onClick={() => batchItemEditShow(record.id)}
                        icon={<EditOutlined />}
                      />
                      <DeleteButton
                        size="small"
                        recordItemId={record.id}
                        hideText
                      />
                    </Space>
                  );
                }}
              />
            </Table>
          </List>
        </Col>
      </Row>
      <OrganizationHasBookCreateModal
        modalProps={organizationHasBookCreateModalProps}
        formProps={organizationHasBookCreateFormProps}
        companyId={`${companyId}`}
        batchItemIds={selectedRowKeys}
      />
      <BatchItemEditModal modalProps={batchItemEditModalProps} formProps={batchItemEditFormProps} batchItem={batchItem}/>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const [companyId, setCompanyId] = useState(0);
  const statusOptions = [{ label: 'SUCCESS', value: 'SUCCESS' }, { label: 'FAILED_NO_FILE', value: 'FAILED_NO_FILE' }];
  const { selectProps: companySelectProps } = useSelect<ICompany>({
    resource: "companies",
    optionLabel: 'name',
    optionValue: 'id',
    sort: [{ field: "name", order: "asc"}],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
  });
  const { selectProps: batchSelectProps, queryResult: batchQueryResult } = useSelect<IBatch>({
    resource: `batches/by-company-id/${companyId}`,
    optionLabel: 'filename',
    optionValue: 'id',
    sort: [{ field: "id", order: "asc" }],
  });
  const updateBatch = (id: any) => {
    setCompanyId(id?id:0);
    batchQueryResult?.refetch();
  }
  return (
    <Form layout="horizontal" {...formProps}>
      <Form.Item label="Company" name="companyId">
        <Select allowClear
                {...companySelectProps}
                onChange={(value) => updateBatch(value)}
                style={{width: '150px'}}/>
      </Form.Item>
      <Form.Item label="Batch" name="batchId">
        <Select allowClear {...batchSelectProps} />
      </Form.Item>
      <Form.Item label="Search" name="q">
        <Input
          placeholder="ID, Title, Content, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Status" name="status">
        <Select allowClear options={statusOptions} placeholder="Status"/>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};

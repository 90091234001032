import {
  Typography,
  useTable,
  List,
  Table,
  TagField,
  Space,
  DeleteButton,
  Button,
  Avatar, DateField
} from "@pankod/refine";
import React, {useState} from "react";
import {IAdminUser, IShelf} from "../../interfaces";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import axios from "axios";
import {SERVER_API_URL} from "../../config";

type ShelfListByUserProps = {
  user?: IAdminUser;
}

const { Title, Text } = Typography;
export const ShelfListByUser:React.FC<ShelfListByUserProps> = ({ user }) => {
  const { tableProps: shelfTableProps , tableQueryResult: shelfTableQueryResult } = useTable<IShelf>({
    resource: `shelves/by-user-id/${user?user.id:0}`,
    initialSorter: [{ field: 'id', order: 'asc'}],
  });

  const returnBook = async (bookId:any, userId:any) => {
    const dto = { userId, bookId };
    const resp = await axios.post(`${SERVER_API_URL}api/book-borrows/return`, dto);
    // console.log(resp.data);
    shelfTableQueryResult?.refetch();
  }

  return (<>
    <List title="Shelf">
      <Table {...shelfTableProps} rowKey="id">
        <Table.Column dataIndex="id" title="id"/>
        <Table.Column dataIndex={["book","thumbnailUrl"]} title="img"
                      render={(value) => (<div>
                        {value?<Avatar src={value} shape="square" size="large" />:null}
                      </div>)}
        />
        <Table.Column dataIndex="bookCode" title="Code"/>
        <Table.Column dataIndex="bookName" title="Name"/>
        <Table.Column dataIndex={["classroom", "name"]} title="Classroom"/>
        <Table.Column dataIndex="startDate"
                      title="startDate"
                      render={(value) => <DateField format="LLL" value={value}/>}
        />
        <Table.Column dataIndex="expectedEndDate"
                      title="expectedEndDate"
                      render={(value) => <DateField format="LLL" value={value}/>}
        />
        <Table.Column dataIndex="status"
                      title="Status"
                      render={(value) => <TagField value={value}/>}
        />
        <Table.Column<IShelf> title="Actions"
                              dataIndex="actions"
                              render={(_text, record): React.ReactNode => {
                                return (
                                  <Space>
                                    {record&&record.status === 'BORROWING'?
                                      <Button
                                        size="small"
                                        onClick={() => returnBook(record.bookId, user?.id)}
                                        icon={<EditOutlined />}
                                      >Return</Button> : null
                                    }
                                    <DeleteButton
                                      size="small"
                                      recordItemId={record.bookId}
                                      resourceName={`shelves/${user?.id}`}
                                      hideText
                                      onSuccess={(value) => {
                                        shelfTableQueryResult?.refetch();
                                      }}
                                    />
                                  </Space>
                                );
                              }}
        />
      </Table>
    </List>
  </>);
}
import {
  Show,
  useShow,
  Typography,
  Tag,
  Select,
  useOne,
  useMany,
  useTable,
  List,
  Table,
  TagField,
  Space,
  ShowButton,
  EditButton,
  DeleteButton,
  Button,
  useModal,
  Modal,
  useModalForm,
  Form,
  Input,
  RefreshButton,
  Row,
  Col,
  Tabs,
  HttpError, CreateButton, Card, useNavigation
} from "@pankod/refine";
import {SERVER_API_URL} from "../../config";
import React from "react";
import {ICompany, IOrganization} from "../../interfaces";
import {EditOutlined} from "@ant-design/icons";
import {ClassroomListByOrganization} from "../classrooms/ClassroomListByOrganization";
import {OrganizationHasBookListByOrganization} from "../organization-has-books/OrganizationHasBookListByOrganization";
import {UserList} from "../user/UserList";
import {UserListByOrganization} from "../user/UserListByOrganization";
import {
  OrganizationCategoryListByOrganization
} from "../organization-categories/OrganizationCategoryListByOrganization";

const { Title, Text } = Typography;
export const OrganizationShow = () => {
  const {show} = useNavigation();
  const { queryResult, setShowId } = useShow<IOrganization>({
    resource: "organizations",
  });
  const { data, isLoading } = queryResult;
  const organization = data?.data;

  return (
    <>
      <Show isLoading={isLoading}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            {organization?.profilePictureId && <div>
                <Title level={5}>Logo</Title>
                <img src={`${SERVER_API_URL}files/${organization?.profilePictureId}/preview`}/>
            </div>}
            {organization?.pictureId && <div>
                <Title level={5}>Picture</Title>
                <img src={`${SERVER_API_URL}files/${organization?.pictureId}/preview`}/>
            </div>}
          </Col>
          <Col span={8}>
            <Title level={5}>Code</Title>
            <Text>{organization?.code}</Text>
            <Title level={5}>Name</Title>
            <Text>{organization?.name}</Text>
            <Title level={5}>Status</Title>
            <Text>
              <Tag>{organization?.status}</Tag>
            </Text>
          </Col>
          <Col span={8}>
            <Title level={5}>Address</Title>
            <Text>{organization?.address?.address}</Text>
            <Title level={5}>Province</Title>
            <Text>{organization?.address?.province}</Text>
            <Title level={5}>Phone</Title>
            <Text>{organization?.phone}</Text>
          </Col>
        </Row>
      </Show>
      <br/>
      <Tabs>
        <Tabs.TabPane tab="User" key="users">
          <UserListByOrganization organization={organization!} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Classroom" key="classrooms">
          <ClassroomListByOrganization organization={organization} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Book" key="books">
          <OrganizationHasBookListByOrganization organization={organization} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Category" key="categories">
          <OrganizationCategoryListByOrganization organization={organization} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  useSelect,
  Collapse,
  Row,
  Col,
  Radio,
  Checkbox,
  useNavigation, useGetIdentity, usePermissions
} from "@pankod/refine";
import {IAdminUser, ICompany, IOrganization} from "../../interfaces";
import AvatarUpload from "../../components/AvatarUpload";
import {TOKEN_KEY} from "../../authProvider";
import {SERVER_API_URL} from "../../config";
import {useState} from "react";
import {RoleUtils} from "../../utils/auth-utils";

export const UserEdit: React.FC = () => {
  const { data: currentUser } = useGetIdentity<IAdminUser>();
  const { data: roles } = usePermissions();
  const isAdmin = RoleUtils.isAdmin(roles);
  const isCompanyAdmin = RoleUtils.isCompanyAdmin(roles);
  const isOrganizationAdmin = RoleUtils.isOrganizationAdmin(roles);

  const {list} = useNavigation();
  const {formProps, saveButtonProps, queryResult} = useForm<IAdminUser>({
    resource: 'admin/users',
    redirect: false,
    onMutationSuccess: () => list('users'),
  });
  const user:IAdminUser|undefined = queryResult?.data?.data;
  const token = localStorage.getItem(TOKEN_KEY);
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const options = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'InActive', value: 'INACTIVE' },
  ];
  const langOptions = [{ label: "English", value: "en" }, { label: "Thai", value: "th"}];
  const roleOptions = ['ROLE_USER'];
  if (isAdmin) {
    roleOptions.push('ROLE_ORGANIZATION_ADMIN');
    roleOptions.push('ROLE_COMPANY_ADMIN');
    roleOptions.push('ROLE_ADMIN');
  } else if (isCompanyAdmin) {
    roleOptions.push('ROLE_ORGANIZATION_ADMIN');
    roleOptions.push('ROLE_COMPANY_ADMIN');
  } else if (isOrganizationAdmin) {
    roleOptions.push('ROLE_ORGANIZATION_ADMIN');
  }
  const [companyId, setCompanyId] = useState(user?.company?.id||0);
  const { selectProps: companySelectProps } = useSelect<ICompany>({
    resource: "companies",
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
  });
  const { selectProps: organizationSelectProps, queryResult: organizationQueryResult } = useSelect<IOrganization>({
    resource: `organizations/by-company-id/${companyId}`,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
  });
  const updateOrganization = (id: any) => {
    setCompanyId(id?id:0);
    organizationQueryResult?.refetch();
  }
  const imgSrc = user?.profilePictureId?`${SERVER_API_URL}files/${user?.profilePictureId}/preview`:undefined;
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} {...layout} layout="horizontal" autoComplete="off">
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item>
              <AvatarUpload
                uploadUrl={`${SERVER_API_URL}api/admin/users/upload-profile-picture/${user?.id}`}
                jwtToken={token}
                onFormChange={(info:any) => console.log(info)}
                imgSrc={imgSrc}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="id" hidden/>
            <Form.Item name="login"  hidden />
            <Form.Item label="Email" name="email"  rules={[{ required: true }]}>
              <Input readOnly={true}/>
            </Form.Item>
            <Form.Item label="Firstname" name="firstName">
              <Input/>
            </Form.Item>
            <Form.Item label="Lastname" name="lastName">
              <Input/>
            </Form.Item>
            <Form.Item label="Language" name="langKey" rules={[{ required: true }]}>
              <Select options={langOptions} />
            </Form.Item>
            {isAdmin?
            <Form.Item label="Company" name={["company", "id"]}>
              <Select allowClear {...companySelectProps} onChange={(value) => updateOrganization(value)}/>
            </Form.Item>:null}
            {isAdmin||isCompanyAdmin?
            <Form.Item label="Organization" name={["organization", "id"]}>
              <Select allowClear {...organizationSelectProps} />
            </Form.Item>:null}
            {isAdmin||isCompanyAdmin||isOrganizationAdmin?<Form.Item label="Roles" name="authorities"  rules={[{ required: true }]}>
              <Checkbox.Group options={roleOptions} />
            </Form.Item>:null}
            <Form.Item label="Notes" name="notes">
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Address" name={['address', 'address']}>
              <Input.TextArea rows={5} />
            </Form.Item>
            <Form.Item label="Amphur" name={['address', 'amphur']}>
              <Input/>
            </Form.Item>
            <Form.Item label="Province" name={['address', 'province']}>
              <Input/>
            </Form.Item>
            <Form.Item label="Postcode" name={['address', 'postcode']}>
              <Input/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
}
import {
  Form,
  Input,
  Select,
  Edit,
  useSelect,
  ModalProps,
  FormProps,
  Modal,
  Col,
  Row,
  Typography, DatePicker
} from "@pankod/refine";
import {IClassroom} from "../../interfaces";
import React, {useState} from "react";
import MomentDatePicker from "../../components/MomentDatePicker";
import moment from "moment";
import dayjs from "dayjs";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";
const { Title, Text } = Typography;

type ClassroomEditProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  classroom?: IClassroom;
};

export const ClassroomEditModal: React.FC<ClassroomEditProps> = ({
                                                                   modalProps,
                                                                   formProps,
                                                                   classroom,
                                                                 }) => {
  // formProps?.form?.setFieldsValue({expiryDate: resp.data.productId})
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  return (
    <Modal {...modalProps}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 16]}>
          <Col span={20}>
            <Title level={5}>Organization</Title>
            <Text>{classroom?.organization?.name}</Text>
            <Form.Item label="Id" name="id" rules={[{ required: true }]}>
              <Input disabled/>
            </Form.Item>
            <Form.Item label="Code" name="code" rules={[{ required: true }]}>
              <Input disabled/>
            </Form.Item>
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input/>
            </Form.Item>
            <Form.Item label="Note" name="notes">
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(
                    <ReactMarkdown>{markdown}</ReactMarkdown>,
                  )
                }
              />
            </Form.Item>
            <Form.Item label="Expiry Date" name="expiryDate" rules={[{ required: true }]}>
              <MomentDatePicker />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

import {Col, Row, Show, Tag, Typography, useShow, Tabs, Card} from "@pankod/refine";
// @ts-ignore
import HTMLRenderer from 'react-html-renderer';
import {SERVER_API_URL} from "../../config";
import {IAdminUser, IBook} from "../../interfaces";
import {ClassroomListByUser} from "../classrooms/ClassroomListByUser";
import {ClassHasBookListByUser} from "../class-has-book/ClassHasBookListByUser";
import {ShelfListByUser} from "../shelf/ShelfListByUser";
import BookDistributionDiagram from "./BookDistributionDiagram";
import axios from "axios";
import {useEffect, useState} from "react";
import {ShelfListByBook} from "../shelf/ShelfListByBook";
import {ReviewListByBook} from "../reviews/ReviewListByBook";
const { Title, Text } = Typography;

export const BookShow: React.FC = () => {
  const [diagramData, setDiagramData] = useState(undefined);
  const { queryResult, setShowId } = useShow<IBook>({
    resource: 'books'
  });
  const { data, isLoading } = queryResult;
  const book = data?.data;

  const loadDiagram = async (bookId: any) => {
    if (bookId) {
      const resp = await axios.get(`${SERVER_API_URL}api/books/distribution/${bookId}`)
      setDiagramData(resp.data);
    }
  }

  useEffect(() => {
    loadDiagram(book?.id);
  }, [book?.id])

  return (
    <>
      <Show isLoading={isLoading}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            {book && book.thumbnailUrl && <img src={book.thumbnailUrl}/>}
            <Title level={5}>Descriptions</Title>
            <HTMLRenderer html={book?.description}/>
          </Col>
          <Col span={8}>
            <Title level={5}>ProductId</Title>
            <Text>{book?.productId}</Text>
            <Title level={5}>Name</Title>
            <Text>{book?.name}</Text>
            <Title level={5}>Article Code</Title>
            <Text>{book?.articleCode}</Text>
            <Title level={5}>Author</Title>
            <Text>{book?.authorName}</Text>
            <Title level={5}>Publisher</Title>
            <Text>{book?.publisherName}</Text>
          </Col>
          <Col span={8}>
            <Title level={5}>Category Level 1</Title>
            <Text>{book?.categoryL1Name}</Text>
            <Title level={5}>Category Level 2</Title>
            <Text>{book?.categoryL2Name}</Text>
          </Col>
        </Row>
      </Show>
      <Tabs>
        <Tabs.TabPane tab="Distribution" key="distribution">
          <Card title="Book">
            <BookDistributionDiagram data={diagramData}/>
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Borrowed" key="borrowed">
          <Card title="Borrowed">
            <ShelfListByBook book={book}/>
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Reviews" key="reviews">
          <Card title="Reviews">
            <ReviewListByBook book={book}/>
          </Card>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
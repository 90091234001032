import {FC, useState} from "react";
import {Divider, Input, Select, Space, Typography} from "@pankod/refine";
import { PlusOutlined } from '@ant-design/icons';
const { Option } = Select;

const CategorySelect : FC = (props: any) => {
  const [defaultItems, setDefaultItems] = useState(['Popular', 'New', 'Recommended']);
  const [items, setItems] = useState(['Popular', 'New', 'Recommended']);
  const [name, setName] = useState('');
  const [currentValue, setCurrentValue] = useState(props.value);
  const onNameChange = (e:any) => {
    setName(e.target.value);
  }
  const addItem = (e:any) => {
    e.preventDefault();
    setItems([...defaultItems, name]);
    setName('');
    setCurrentValue(name)
  }
  return <Select
    value={currentValue}
    style={{ width: 300 }}
    placeholder="Category"
    onSelect={(val:any) => {
      setCurrentValue(val);
      props.onChange(val);
    }}
    dropdownRender={menu => (
      <>
        {menu}
        <Divider style={{ margin: '8px 0' }} />
        <Space align="center" style={{ padding: '0 8px 4px' }}>
          <Input placeholder="Please enter category" value={name} onChange={onNameChange} />
          <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap' }}>
            <PlusOutlined /> Add item
          </Typography.Link>
        </Space>
      </>
    )}
  >
    {items.map(item => (
      <Option key={item}>{item}</Option>
    ))}
  </Select>;
}

export default CategorySelect;
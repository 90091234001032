import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useMany,
  FilterDropdown,
  Select,
  ShowButton,
  useSelect,
  Radio,
  Space,
  EditButton,
  DeleteButton,
  FilterDropdownProps,
  Input,
  getDefaultFilter,
  HttpError,
  CrudFilters,
  Row,
  Col,
  Card,
  FormProps,
  Form,
  Icons, Button, CreateButton, Typography, message, Upload, useDrawerForm, useModalForm, Avatar,
} from "@pankod/refine";

import {ICompany, IBatch, IBatchFilterVariables, IAdminUser, IClassroom, IBook} from "../../interfaces";
import React, {useEffect, useState} from "react";
import {authProvider, TOKEN_KEY} from "../../authProvider";
import {EditOutlined, PlusOutlined, UploadOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {SERVER_API_URL} from "../../config";

const { Title, Text } = Typography;

function beforeUpload(file:any) {
  const isXls = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  if (!isXls) {
    message.error('You can only upload Excel file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isXls && isLt2M;
}

export const BookList: React.FC = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  const [user, setUser] = useState<any>({});
  const getUser = async () => {
    // @ts-ignore
    const resp = await authProvider?.getUserIdentity();
    setUser(resp||{});
  }
  useEffect(() => {
    getUser();
  }, []);

  // Create Modal
  const {
    modalProps: batchCreateModalProps,
    formProps: batchCreateFormProps,
    show: batchCreateModalShow,
  } = useModalForm<IBatch, HttpError>({
    action: "create",
    resource: 'batches',
    redirect: false,
    onMutationSuccess: () => tableQueryResult?.refetch(),
  });

  const uploadButtonProps = {
    name: 'file',
    action: `${SERVER_API_URL}api/book-import/upload-xls`,
    beforeUpload: beforeUpload,
    headers: {
      authorization: `${token}`,
    },
    onChange(info:any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  }

  const {tableProps, tableQueryResult, searchFormProps, filters} = useTable<
    IBook,
    HttpError,
    IBatchFilterVariables>({
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, status } = params;
      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      }, {
        field: "status",
        operator: 'eq',
        value: status,
      });
      return filters;
    }
  });

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <Card title="Filters">
            <Filter formProps={searchFormProps} />
          </Card>
        </Col>
      </Row>
        <br/>
      <Row gutter={[16, 16]}>
        <Col lg={24} xs={24}>
          <List>
            <Table {...tableProps} rowKey="id">
              <Table.Column dataIndex="id" title="id"/>
              <Table.Column dataIndex="thumbnailUrl" title="img"
                            render={(value) => (<div>
                              {value?<Avatar src={value} shape="square" size="large" />:null}
                            </div>)}
              />
              <Table.Column dataIndex="articleCode" title="ArticleCode"/>
              <Table.Column dataIndex="name" title="name"/>
              <Table.Column dataIndex="authorName" title="Author"/>
              <Table.Column dataIndex="publisherName" title="Publisher"/>
              <Table.Column dataIndex="status" title="Status"/>
              <Table.Column<IBook>
                title="Actions"
                dataIndex="actions"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <ShowButton
                        size="small"
                        recordItemId={record.id}
                        hideText
                      />
                    </Space>
                  );
                }}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="horizontal" {...formProps}>
      <Form.Item label="Search" name="q">
        <Input.Search
          placeholder="Name, Author, Publisher, ArticleCode, etc."
          prefix={<Icons.SearchOutlined />}
          onSearch={(value) => formProps.form?.submit()}
        />
      </Form.Item>
    </Form>
  );
};
import moment from 'moment';
import dayjs from 'dayjs';

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';

export const convertDateTimeFromServer = (date:any) => (date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null);
export const convertDateTimeToServer = (date:any) => (date ? dayjs(date).toDate() : null);
// export const displayDefaultDateTime2 = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const displayDefaultDateTime = () => dayjs().startOf('day');
// export const displayDefaultDateTime2 = () => moment().startOf('day');
// export const convertDateTimeFromServer = (date:any) => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);
// export const convertDateTimeToServer = (date:any) => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

import {
  Typography,
  useTable,
  List,
  Table,
  TagField,
  Space,
  DeleteButton,
  Button,
  Avatar
} from "@pankod/refine";
import React, {useState} from "react";
import {IAdminUser, IBook, IReview, IShelf} from "../../interfaces";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import axios from "axios";
import {SERVER_API_URL} from "../../config";

type ReviewListByBookProps = {
  book?: IBook;
}

const { Title, Text } = Typography;
export const ReviewListByBook:React.FC<ReviewListByBookProps> = ({ book }) => {
  const { tableProps: reviewTableProps , tableQueryResult: reviewTableQueryResult } = useTable<IReview>({
    resource: `v2/reviews/by-book-id/${book?book.id:0}`,
    initialSorter: [{ field: 'id', order: 'asc'}],
  });

  return (<>
    <List title="Shelf">
      <Table {...reviewTableProps} rowKey="id">
        <Table.Column dataIndex={["book","thumbnailUrl"]} title="img"
                      render={(value) => (<div>
                        {value?<Avatar src={value} shape="square" size="large" />:null}
                      </div>)}
        />
        <Table.Column dataIndex={["book", "name"]} title="Book"/>
        <Table.Column dataIndex={["user", "login"]} title="Reviewer"/>
        <Table.Column dataIndex="rating" title="Rating"/>
        <Table.Column dataIndex="review" title="Review"/>
        <Table.Column<IReview>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <DeleteButton
                  size="small"
                  recordItemId={record.id}
                  resourceName={`reviews`}
                  hideText
                  onSuccess={(value) => {
                    reviewTableQueryResult?.refetch();
                  }}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  </>);
}
import {useForm, Form, Input, Select, Edit, useSelect, Collapse, Row, Col, Radio} from "@pankod/refine";
import "react-mde/lib/styles/css/react-mde-all.css";
import {ICompany} from "../../interfaces";
import AvatarUpload from "../../components/AvatarUpload";
import {TOKEN_KEY} from "../../authProvider";
import {SERVER_API_URL} from "../../config";
import React, {useState} from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

export const CompanyEdit: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const {formProps, saveButtonProps, queryResult} = useForm<ICompany>();
  // console.log(JSON.stringify(queryResult));
  const company:any = queryResult?.data?.data;
  const token = localStorage.getItem(TOKEN_KEY);
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const options = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'InActive', value: 'INACTIVE' },
  ];
  const imgSrc = company?.profilePictureId?`${SERVER_API_URL}files/${company?.profilePictureId}/preview`:undefined;
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} {...layout} layout="horizontal" autoComplete="off">
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item>
              <AvatarUpload
                uploadUrl={`${SERVER_API_URL}api/companies/upload-profile-picture/${company?.id}`}
                jwtToken={token}
                onFormChange={(info:any) => console.log(info)}
                imgSrc={imgSrc}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="id" hidden/>
            <Form.Item label="Code" name="code">
              <Input disabled/>
            </Form.Item>
            <Form.Item label="Name" name="name">
              <Input/>
            </Form.Item>
            <Form.Item label="Status" name="status">
              <Radio.Group options={options}/>
            </Form.Item>
            <Form.Item label="Notes" name="notes">
              <ReactMde
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(
                    <ReactMarkdown>{markdown}</ReactMarkdown>,
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Phone" name="phone">
              <Input/>
            </Form.Item>
            <Form.Item label="Address" name={['address', 'address']}>
              <Input.TextArea rows={5} />
            </Form.Item>
            <Form.Item label="Amphur" name={['address', 'amphur']}>
              <Input/>
            </Form.Item>
            <Form.Item label="Province" name={['address', 'province']}>
              <Input/>
            </Form.Item>
            <Form.Item label="Postcode" name={['address', 'postcode']}>
              <Input/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};

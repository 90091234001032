import {
  Show,
  useShow,
  Typography,
  Tag,
  Select,
  useOne,
  useMany,
  useTable,
  List,
  Table,
  TagField,
  Space,
  ShowButton,
  EditButton,
  DeleteButton,
  Button,
  useModal,
  Modal,
  useModalForm,
  Form,
  Input,
  RefreshButton,
  Row,
  Col,
  Tabs,
  HttpError, CreateButton, Card, useNavigation, FormProps, useSelect, Icons, CrudFilters
} from "@pankod/refine";
import {SERVER_API_URL} from "../../config";
import React, {useState} from "react";
import {IClassroom, IClassroomFilterVariables, ICompany, IOrganization, IOrganizationHasBook} from "../../interfaces";
import {EditOutlined, EyeOutlined} from "@ant-design/icons";
import {ClassroomCreateModal} from "./ClassroomCreateModal";
import {ClassroomEditModal} from "./ClassroomEditModal";

const { Title, Text } = Typography;
export const ClassroomList:React.FC = () => {
  const {show} = useNavigation();
  const { tableProps, searchFormProps, filters, tableQueryResult } = useTable<IClassroom, HttpError, IClassroomFilterVariables>({
    resource: "classrooms",
    initialSorter: [{ field: 'id', order: 'asc'}],
    syncWithLocation: false,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, companyId, organizationId } = params;
      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      }, {
        field: "companyId",
        operator: 'eq',
        value: companyId,
      }, {
        field: "organizationId",
        operator: 'eq',
        value: organizationId,
      });
      return filters;
    }
  });

  return (<>
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <Card title="Filters">
          <Filter formProps={searchFormProps} />
        </Card>
      </Col>
    </Row>
    <List title="Classrooms">
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="id"/>
        <Table.Column dataIndex="code" title="Code"/>
        <Table.Column
          dataIndex="name"
          title="Name"
          render={(value) => <TagField value={value}/>}
        />
        <Table.Column<IClassroom>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <Button
                  size="small"
                  onClick={() => show("classrooms", record.id!)}
                  icon={<EyeOutlined />}
                >Manage</Button>
                <DeleteButton
                  size="small"
                  recordItemId={record.id}
                  resourceName="classrooms"
                  hideText
                  onSuccess={(value) => {
                    tableQueryResult?.refetch();
                  }}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  </>);
}

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const activatedOptions = [{ label: 'Active', value: true }, { label: 'InActive', value: false }];
  const [companyId, setCompanyId] = useState(0);
  const { selectProps: companySelectProps } = useSelect<ICompany>({
    resource: "companies",
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
  });
  const { selectProps: organizationSelectProps, queryResult: organizationQueryResult } = useSelect<IOrganization>({
    resource: `organizations/by-company-id/${companyId}`,
    optionLabel: 'name',
    optionValue: 'id',
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    /*
    filters: [
      {
        field: "companyId",
        operator: "eq",
        value: companyId,
      },
    ],
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value
      }
    ]
     */
  });
  const updateOrganization = (id: any) => {
    setCompanyId(id?id:0);
    organizationQueryResult?.refetch();
  }
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Search" name="q">
        <Input
          placeholder="ID, Name, Email, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Company" name="companyId">
        <Select allowClear {...companySelectProps} onChange={(value) => updateOrganization(value)}/>
      </Form.Item>
      <Form.Item label="Organization" name="organizationId">
        <Select allowClear {...organizationSelectProps} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
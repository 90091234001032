import {
  Show,
  useShow,
  Typography,
  Tag,
  Row,
  Col,
  Tabs,
  HttpError, CreateButton, Card, useNavigation
} from "@pankod/refine";
import {SERVER_API_URL} from "../../config";
import React from "react";
import {IClassroom, ICompany, IOrganization} from "../../interfaces";
import {EditOutlined} from "@ant-design/icons";
import {ClassHasUserList} from "../class-has-user/ClassHasUserList";
import {ClassHasBookList} from "../class-has-book/ClassHasBookList";

const { Title, Text } = Typography;
export const ClassroomShow = () => {
  const {show} = useNavigation();
  const { queryResult, setShowId } = useShow<IClassroom>({
    resource: "classrooms",
  });
  const { data, isLoading } = queryResult;
  const classroom = data?.data;

  return (
    <>
      <Show isLoading={isLoading}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            {classroom?.profilePictureId?<img src={`${SERVER_API_URL}files/${classroom?.profilePictureId}/preview`}/>:null}
          </Col>
          <Col span={8}>
            <Title level={5}>Code</Title>
            <Text>{classroom?.code}</Text>
            <Title level={5}>Name</Title>
            <Text>{classroom?.name}</Text>
            <Title level={5}>Status</Title>
            <Text>
              <Tag>{classroom?.status}</Tag>
            </Text>
          </Col>
          <Col span={8}>
            <Title level={5}>Organization</Title>
            <Text>{classroom?.organization?.code}</Text>
            <Title level={5}>Province</Title>
            <Text>{classroom?.organization?.name}</Text>
          </Col>
        </Row>
      </Show>
      <br/>
      <Tabs>
        <Tabs.TabPane tab="User" key="users">
          <ClassHasUserList classroom={classroom!} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Book" key="books">
          <ClassHasBookList classroom={classroom!} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
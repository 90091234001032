import {
  useForm,
  Form,
  Input,
  Select,
  Edit,
  useSelect,
  ModalProps,
  FormProps,
  Modal,
  useTable,
  HttpError, CrudFilters, Table, TagField, Transfer
} from "@pankod/refine";
import {stringify} from "query-string";
import {IBatchItem, IBatchItemFilterVariables, IBook, ICompany, IOrganization} from "../../interfaces";
import React, {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import axios from "axios";
import {SERVER_API_URL} from "../../config";
import {TransferDirection} from "antd/lib/transfer";
import CategorySelect from "../../components/CategorySelect";

type OrganizationCategoryCreateProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  organizationId: string|undefined;
};

export const OrganizationCategoryCreateModal: React.FC<OrganizationCategoryCreateProps> = ({
                                                                                             modalProps,
                                                                                             formProps,
                                                                                             organizationId,
                                                                                           }) => {
  const [availableBooks, setAvailableBooks] = useState<any>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  useEffect(() => {
    loadAvailableBooks(organizationId);
  }, [organizationId]);

  const loadAvailableBooks = async (organizationId: any) => {
    if (organizationId) {
      const resp = await axios.get<[IBook]>(`${SERVER_API_URL}api/organization-has-books/available-books/${organizationId}`);
      const list = resp.data.map((o:IBook) => {
        return {
          key: o.id,
          label: o.name,
          description: o.name,
        };
      });
      setAvailableBooks(list);
    }
  }

  return (
    <Modal {...modalProps} title="Create New Category">
      <Form {...formProps}
            layout="vertical"
            initialValues={{
              organizationId,
            }}
            onFinish={(values) => {
              return formProps.onFinish?.({
                organizationId,
                ...values,
              });
            }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <CategorySelect/>
        </Form.Item>
        <Form.Item label="Books" name="bookIds" rules={[{ required: true }]}>
          <Transfer
            showSearch
            dataSource={availableBooks}
            titles={['All', 'Selected']}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onSelectChange={(sourceSelectedKeys, targetSelectedKeys)=>
              setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])}
            onChange={(nextTargetKeys) => setTargetKeys(nextTargetKeys)}
            render={(item) => item.label!}
            listStyle={{ width: 400, height: 400 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useMany,
  FilterDropdown,
  Select,
  ShowButton,
  useSelect,
  Radio,
  Space,
  EditButton,
  DeleteButton,
  FilterDropdownProps,
  Input,
  getDefaultFilter,
  HttpError,
  CrudFilters,
  Row,
  Col,
  Card,
  FormProps,
  Form,
  Icons,
  Button,
  CreateButton,
  Typography,
  message,
  Upload,
  useDrawerForm,
  useModalForm,
  AntdList,
  useSimpleList,
  ModalProps, Modal, Avatar,
} from "@pankod/refine";

import {
  ICompany,
  IBatch,
  IBatchFilterVariables,
  IAdminUser,
  IClassroom,
  IBook,
  IClassHasBook,
  IOrganizationHasBook
} from "../../interfaces";
import React, {useEffect, useState} from "react";
import {authProvider, TOKEN_KEY} from "../../authProvider";
import {EditOutlined, PlusOutlined, UploadOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {SERVER_API_URL} from "../../config";
import {BookItem} from "../../components/BookItem";

const { Title, Text } = Typography;

type LibraryListModalByOrganizationHasBookProps = {
  modalProps: ModalProps;
  organizationHasBook: IOrganizationHasBook;
  onClose: any;
}

export const LibraryListModalByOrganizationHasBook: React.FC<LibraryListModalByOrganizationHasBookProps> = ({
                                                                                                              modalProps,
                                                                                                              organizationHasBook,
                                                                                                              onClose,
                                                                                                            }) => {
  const { tableProps, tableQueryResult } = useTable<IOrganizationHasBook, HttpError>({
    resource: `libraries/by-organization-has-book-id/${organizationHasBook&&organizationHasBook.id?organizationHasBook.id:0}`,
  });

  useEffect(() => {
    tableQueryResult?.refetch();
  }, [organizationHasBook]);

  const thumbnailUrl = organizationHasBook?.book?.thumbnailUrl?organizationHasBook?.book?.thumbnailUrl:null;

  return (<>
      <Modal {...modalProps}
             title={`Library - ${organizationHasBook?.book?.name}`}
             width={1000}
             footer={[
                 <Button key="back" onClick={() => onClose()} type="primary">
                   Close
                 </Button>
             ]}
      >
        {thumbnailUrl?<Avatar src={thumbnailUrl} shape="square" size="large" />:null}
        <Table {...tableProps} rowKey="id" size="small">
          <Table.Column dataIndex="slotNumber" title="Slot" />
          <Table.Column dataIndex="classroom" title="Classroom"/>
          <Table.Column dataIndex="borrowerUser" title="Borrower"/>
          <Table.Column dataIndex="borrowedDate" title="Borrowed Date"/>
          <Table.Column dataIndex="expectedReturnDate" title="Expected Return Date"/>
          <Table.Column
            dataIndex="status"
            title="Status"
            render={(value) => <TagField value={value}/>}
          />
        </Table>
      </Modal>
    </>
  );
};

import {
  Show,
  useShow,
  Typography,
  Tag,
  Select,
  useOne,
  useMany,
  useTable,
  List,
  Table,
  TagField,
  Space,
  ShowButton,
  EditButton,
  DeleteButton,
  Button,
  useModalForm,
  Row,
  Col,
  HttpError, CreateButton, Card, useNavigation, message, Upload
} from "@pankod/refine";
import {IBatch, IBatchItem, IBook, IOrganization} from "../../interfaces";
import {EditOutlined, ReloadOutlined, SendOutlined, PlusSquareOutlined, UploadOutlined} from "@ant-design/icons";
import React from "react";
import {BatchItemCreateModal} from "../batch-items/BatchItemCreateModal";
import {BatchItemEditModal} from "../batch-items/BatchItemEditModal";
import axios from "axios";
import {SERVER_API_URL} from "../../config";
import {TOKEN_KEY} from "../../authProvider";

const { Title, Text } = Typography;


function beforeUpload(file:any) {
  const isXls = file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  if (!isXls) {
    message.error('You can only upload Excel file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isXls && isLt2M;
}

export const BatchShow = () => {
  const token = localStorage.getItem(TOKEN_KEY);

  // Create Modal
  const {
    modalProps: batchItemCreateModalProps,
    formProps: batchItemCreateFormProps,
    show: batchItemCreateModalShow,
  } = useModalForm<IBatchItem, HttpError>({
    action: "create",
    resource: 'book-import/create-batch-item',
    redirect: false,
    onMutationSuccess: () => tableQueryResult?.refetch(),
  });

  // Edit Modal
  const {
    modalProps: batchItemEditModalProps,
    formProps: batchItemEditFormProps,
    show: batchItemEditShow,
    queryResult: batchItemQueryResult,
    formLoading,
  } = useModalForm<IBatchItem, HttpError, IBatchItem>({
    action: "edit",
    resource: "batch-items",
    redirect: false,
    onMutationSuccess: () => tableQueryResult?.refetch(),
  });
  // const { data: organizationData } = organizationQueryResult;
  // const organization = organizationData?.data;
  const { queryResult, setShowId } = useShow<IBatch>({
    resource: "batches",
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const {show} = useNavigation();

  const batchItem:IBatchItem|undefined = batchItemQueryResult?.data?.data;

  const uploadButtonProps = {
    name: 'file',
    action: `${SERVER_API_URL}api/book-import/upload-xls/${record?.id}`,
    beforeUpload: beforeUpload,
    headers: {
      authorization: `${token}`,
    },
    onChange(info:any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        batchItemQueryResult?.refetch();
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  }

  const { tableProps, tableQueryResult } = useTable<IBatchItem>({
    resource: record?`batch-items/by-batch-id/${record?.id}`:'batch-items/by-batch-id/0',
    initialSorter: [{ field: 'id', order: 'asc'}],
  });

  const reDownload = async (productId:any) => {
    const resp = await axios.post<IBook>(`${SERVER_API_URL}api/book-import/download-book-by-product-id/${productId}`);
    console.log(resp.data);
  }

  return (
    <>
      <Show isLoading={isLoading}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Title level={5}>Name</Title>
            <Text>{record?.filename}</Text>
            <Title level={5}>Status</Title>
            <Text>
              <Tag>{record?.status}</Tag>
            </Text>
          </Col>
          <Col span={16}>
            <Title level={5}>totalItems</Title>
            <Text>{record?.totalItems}</Text>
            <Title level={5}>numberOfSuccess</Title>
            <Text>{record?.numberOfSuccess}</Text>
            <Title level={5}>numberOfFailed</Title>
            <Text>{record?.numberOfFailed}</Text>
            <Title level={5}>numberOfPublished</Title>
            <Text>{record?.numberOfPublished}</Text>
          </Col>
        </Row>
      </Show>
      <br/>
      <List
        title="Items"
        pageHeaderProps={{
          extra: <Space>
            <Upload {...uploadButtonProps}

            >
              <Button icon={<UploadOutlined />}>Import XLS</Button>
            </Upload>,
            <Button
              onClick={() => batchItemCreateModalShow()}
              size="small"
              icon={<PlusSquareOutlined />}
            >Add book</Button>
          </Space>
        }}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="id"/>
          <Table.Column dataIndex="itemNo" title="No."/>
          <Table.Column dataIndex="productId" title="ProductId"/>
          <Table.Column dataIndex="bookName" title="Name"/>
          <Table.Column dataIndex="concurrency" title="Concurrency"/>
          <Table.Column dataIndex="status"
                        title="Status"
                        render={(value) => <TagField value={value}/>}
          />
          <Table.Column<IBatchItem>
            title="Actions"
            dataIndex="actions"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <ShowButton
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                  <Button
                    size="small"
                    onClick={() => batchItemEditShow(record.id)}
                    icon={<EditOutlined />}
                  />
                  <Button
                    size="small"
                    onClick={() => reDownload(record.productId)}
                    icon={<ReloadOutlined />}
                  />
                  <DeleteButton
                    size="small"
                    recordItemId={record.id}
                    resourceName="batch-items"
                    hideText
                    onSuccess={(value) => {
                      tableQueryResult?.refetch();
                    }}
                  />
                </Space>
              );
            }}
          />
        </Table>
      </List>

      <BatchItemCreateModal modalProps={batchItemCreateModalProps} formProps={batchItemCreateFormProps} batchId={record?.id} />
      <BatchItemEditModal modalProps={batchItemEditModalProps} formProps={batchItemEditFormProps} batchItem={batchItem}/>
    </>
  );
};

import {Refine, Space} from "@pankod/refine";
import routerProvider from "@pankod/refine-react-router";

import "@pankod/refine/dist/styles.min.css";
import simpleRestDataProvider from "@pankod/refine-simple-rest";
import { authProvider } from "authProvider";
import { useTranslation } from "react-i18next";
import { Header } from "./components/layout";
import { PostList } from "./pages/posts/list";
import axios from "axios";
import jhipsterRestDataProvider from "./api/jhipsterRestDataProvider";
import {CompanyList} from "./pages/companies/CompanyList";
import {CompanyEdit} from "./pages/companies/CompanyEdit";
import {CompanyCreate} from "./pages/companies/CompanyCreate";
import {CompanyShow} from "./pages/companies/CompanyShow";
import {OrganizationEdit} from "./pages/organization/OrganizationEdit";
import {SERVER_API_URL} from "./config";
import {AuthenticatedUserProfile} from "./pages/user-profile/UserProfile";
import {UserList} from "./pages/user/UserList";
import {UserCreate} from "./pages/user/UserCreate";
import {UserEdit} from "./pages/user/UserEdit";
import {UserShow} from "./pages/user/UserShow";
import {OrganizationShow} from "./pages/organization/OrganizationShow";
import {OrganizationList} from "./pages/organization/OrganizationList";
import {BatchList} from "./pages/batches/BatchList";
import {BatchShow} from "./pages/batches/BatchShow";
import {BatchItemList} from "./pages/batch-items/BatchItemList";
import {ClassroomShow} from "./pages/classrooms/ClassroomShow";
import {BookList} from "./pages/books/BookList";
import React from "react";
import {ClassroomList} from "./pages/classrooms/ClassroomList";
import {BookShow} from "./pages/books/BookShow";
import {DynamicSider} from "./components/layout/dynamic-sider/DynamicSider";
import {Login} from "./pages/login/Login";

function App() {
  const { t, i18n } = useTranslation();

  // const API_URL = "https://api.fake-rest.refine.dev";
  const API_URL = `${SERVER_API_URL}api`;
  const dataProvider = jhipsterRestDataProvider(API_URL, axios);

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const { Link } = routerProvider;

  return (
    <Refine
      routerProvider={{
        ...routerProvider,
        routes: [{
          exact: true,
          component: AuthenticatedUserProfile,
          path: "/user-profile",
        }]
      }}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      Header={Header}
      Sider={DynamicSider}
      LoginPage={Login}
      Title={() => (
        <Link to="/" style={{ float: "left", marginRight: "10px", marginLeft: "20px", marginTop: "10px" }}>
          <Space>
            <img src="/images/ant-logo.svg" alt="Library" style={{ width: "20px" }} />
            <div>Naiin Library</div>
          </Space>
        </Link>
      )}
      resources={[
        {
          name: "books",
          show: BookShow,
          list: BookList,
        }, {
          name: "batches",
          list: BatchList,
          show: BatchShow,
        }, {
          name: "batch-items",
          list: BatchItemList,
        }, {
          name: "users",
          list: UserList,
          show: UserShow,
          edit: UserEdit,
        }, {
          name: "companies",
          list: CompanyList,
          show: CompanyShow,
          edit: CompanyEdit,
          create: CompanyCreate,
        }, {
          name: "organizations",
          list: OrganizationList,
          show: OrganizationShow,
        }, {
          name: "classrooms",
          list: ClassroomList,
          show: ClassroomShow,
        },
      ]}
    ></Refine>
  );
}

export default App;

import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  useMany,
  FilterDropdown,
  Select,
  ShowButton,
  useSelect,
  Radio,
  Space,
  EditButton,
  DeleteButton,
  FilterDropdownProps,
  Input,
  getDefaultFilter,
  HttpError,
  CrudFilters,
  Row,
  Col,
  Card,
  FormProps,
  Form,
  Icons, Button,
} from "@pankod/refine";

import {IPost, ICategory, ICompany, ICompanyFilterVariables} from "../../interfaces";

export const CompanyList: React.FC = () => {
  const {tableProps, searchFormProps, filters} = useTable<
    ICompany,
    HttpError,
    ICompanyFilterVariables>({
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, status } = params;
      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      }, {
        field: "status",
        operator: 'eq',
        value: status,
      });
      return filters;
    }
  });

  return (
    <Row gutter={[16, 16]}>
      <Col lg={6} xs={24}>
        <Card title="Filters">
          <Filter formProps={searchFormProps} />
        </Card>
      </Col>
      <Col lg={18} xs={24}>
        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="id" title="id"/>
            <Table.Column dataIndex="code" title="code"/>
            <Table.Column dataIndex="name" title="name"
                          filterDropdown={(props: FilterDropdownProps) => (
                            <FilterDropdown {...props}>
                              <Input />
                            </FilterDropdown>
                          )}
                          defaultFilteredValue={getDefaultFilter("name", filters, "contains")}
            />
            <Table.Column
              dataIndex="status"
              title="Status"
              render={(value) => <TagField value={value}/>}
              filterDropdown={(props) => (
                <FilterDropdown {...props}>
                  <Radio.Group>
                    <Radio value="ACTIVE">ACTIVE</Radio>
                    <Radio value="INACTIVE">INACTIVE</Radio>
                  </Radio.Group>
                </FilterDropdown>
              )}
            />
            <Table.Column<ICompany>
              title="Actions"
              dataIndex="actions"
              render={(_text, record): React.ReactNode => {
                return (
                  <Space>
                    <ShowButton
                      size="small"
                      recordItemId={record.id}
                      hideText
                    />
                    <EditButton
                      size="small"
                      recordItemId={record.id}
                      hideText
                    />
                    <DeleteButton
                      size="small"
                      recordItemId={record.id}
                      hideText
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Form.Item label="Search" name="q">
        <Input
          placeholder="ID, Title, Content, etc."
          prefix={<Icons.SearchOutlined />}
        />
      </Form.Item>
      <Form.Item label="Status" name="status">
        <Select
          allowClear
          options={[
            {
              label: "ACTIVE",
              value: "ACTIVE",
            },
            {
              label: "INACTIVE",
              value: "INACTIVE",
            },
          ]}
          placeholder="Company Status"
        />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Filter
        </Button>
      </Form.Item>
    </Form>
  );
};
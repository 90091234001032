import {
  Show,
  useShow,
  Typography,
  Tag,
  Select,
  useOne,
  useMany,
  useTable,
  List,
  Table,
  TagField,
  Space,
  ShowButton,
  EditButton,
  DeleteButton,
  Button,
  useModal,
  Modal,
  useModalForm,
  Form,
  Input,
  RefreshButton,
  Row,
  Col,
  Tabs,
  HttpError, CreateButton, Card, useNavigation
} from "@pankod/refine";
import {SERVER_API_URL} from "../../config";
import React from "react";
import {
  IClassroom,
  ICompany,
  IOrganization,
  IOrganizationCategory, IOrganizationCategoryEdit,
  IOrganizationCategorySummary,
  IOrganizationHasBook
} from "../../interfaces";
import {EditOutlined} from "@ant-design/icons";
import {OrganizationCategoryCreateModal} from "./OrganizationCategoryCreateModal";
import {OrganizationCategoryEditModal} from "./OrganizationCategoryEditModal";

type OrganizationCategoryListByOrganizationProps = {
  organization?: IOrganization;
}

const { Title, Text } = Typography;
export const OrganizationCategoryListByOrganization:React.FC<OrganizationCategoryListByOrganizationProps> = ({ organization }) => {
  const { tableProps: organizationCategoryTableProps , tableQueryResult: organizationCategoryTableQueryResult } = useTable<IOrganizationCategorySummary>({
    resource: `organization-categories/distinct-name-by-organization-id/${organization?organization.id:0}`,
    initialSorter: [{ field: 'seq', order: 'asc'}],
  });

  // Create Modal
  const {
    modalProps: createOrganizationCategoryModalProps,
    formProps: createOrganizationCategoryFormProps,
    show: createOrganizationCategoryModalShow,
  } = useModalForm<IOrganizationCategoryEdit, HttpError>({
    action: "create",
    resource: 'organization-categories',
    redirect: false,
    onMutationSuccess: () => organizationCategoryTableQueryResult?.refetch(),
  });

  // Edit Modal
  const {
    modalProps: editOrganizationCategoryModalProps,
    formProps: editOrganizationCategoryFormProps,
    show: editOrganizationCategoryShow,
    queryResult: editOrganizationCategoryQueryResult,
  } = useModalForm<IOrganizationCategoryEdit, HttpError, IOrganizationCategoryEdit>({
    action: "edit",
    resource: `organization-categories/update-dto/${organization?organization.id:0}`,
    redirect: false,
    onMutationSuccess: () => organizationCategoryTableQueryResult?.refetch(),
  });
  const editOrganizationCategory:IOrganizationCategoryEdit|undefined = editOrganizationCategoryQueryResult?.data?.data;
  return (<>
    <List
      title="Categories"
      pageHeaderProps={{
        extra: <CreateButton onClick={() => createOrganizationCategoryModalShow()} />
      }}
    >
      <Table {...organizationCategoryTableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          title="Name"
          render={(value) => <TagField value={value}/>}
        />
        <Table.Column dataIndex="total" title="Total"/>
        <Table.Column<IOrganizationCategorySummary>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => {
            return (
              <Space>
                <Button
                  size="small"
                  onClick={() => editOrganizationCategoryShow(record.name)}
                  icon={<EditOutlined />}
                />
                <DeleteButton
                  size="small"
                  recordItemId={record.name}
                  resourceName={`organization-categories/${organization?organization.id:0}`}
                  hideText
                  onSuccess={(value) => {
                    organizationCategoryTableQueryResult?.refetch();
                  }}
                />
              </Space>
            );
          }}
        />
      </Table>
    </List>
    <OrganizationCategoryCreateModal modalProps={createOrganizationCategoryModalProps} formProps={createOrganizationCategoryFormProps} organizationId={organization?.id} />
    <OrganizationCategoryEditModal modalProps={editOrganizationCategoryModalProps} formProps={editOrganizationCategoryFormProps} organizationId={organization?.id} data={editOrganizationCategory} />
  </>);
}